import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { AttachmentListManager } from '../../model/widget/attachmment-list-manager.model';
import { AttachedFile } from '@saep-ict/pouch_agent_models';
import { AngularCoreUtilService } from '../../service/util/util.service';
import * as _ from 'lodash';
import { AttachmentResolverService } from '../../service/structure/attachment-resolver.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DialogConfirmComponent } from '../dialog-confirm/dialog-confirm.component';
import { TranslateService } from '@ngx-translate/core';
// import { UtilAttachmentListManager } from '../../service/util/attachment-list-manager.service';
import { SentencecasePipe } from '../../pipes/sentencecase.pipe';
import { UtilMatSnackBar } from '../../service/util/mat-snack-bar.service';
import { CustomHeadersHandlerService } from '../../service/structure/custom-headers-handler.service';
import { APP_CONFIG_TOKEN, ISaepIctAngularCoreAppConfig } from '../../model/structure/lib-app-config.interface';
import * as UtilFile from '../../misc/util/util-file';
import { AttachmentListManagerEnum } from '../../enum/attachment-list-manager.enum';
import { UploadService } from '../../service/upload/upload.service';
import { finalize } from 'rxjs';
import { WebSocketMessageModel } from '../../model/websocket.model';
import { WebSocketActionEnum, WebSocketChannelTypeCodeEnum, WebSocketTopicStateEnum } from '../../enum/websocket.enum';
import { RestAttachmentListManagerService } from '../../service/rest/rest-attachment-list-manager.service';
import { RxStompService } from '../../service/rx-stomp/rx-stomp.service';
import * as UtilDownload from '../../constants/util-download';
import { DialogImageComponent } from '../dialog-image/dialog-image.component';

@Component({
	selector: 'attachment-list-manager',
	templateUrl: './attachment-list-manager.component.html',
	styleUrls: ['./attachment-list-manager.component.scss']
})
export class AttachmentListManagerComponent {
	@Input() set configuration(e: AttachmentListManager.Configuration<AttachedFile>) {
    if (e) {
      e = _.cloneDeep(e);
      this.customHeadersHandlerService.addCustomHeaders(e.customHeaders);
      this.setAttachmentListConfiguration(e);
    }
  };
  _configuration: AttachmentListManager.Configuration<AttachedFile>;
	@Output() attachmentListchange: EventEmitter<AttachedFile[]> = new EventEmitter();

  attachmentListToAdd: File[];
	downloadThroughIframeId = this.utilService.guid();

  // Anche se non utilizzato nel template va assegnato in modo non perda il contesto
  // this all'interno del metodo `downloadArchiveSimulatingClick`
  utilDownload = UtilDownload;

	constructor(
    // private utilAttachmentListManager: UtilAttachmentListManager,
    private uploadService: UploadService,
    private utilService: AngularCoreUtilService,
    private attachmentResolverService: AttachmentResolverService,
    private matDialog: MatDialog,
    private utilMatSnackBar : UtilMatSnackBar,
    private sentencecasePipe: SentencecasePipe,
    private translateService: TranslateService,
    private customHeadersHandlerService: CustomHeadersHandlerService,
    private restAttachmentListService: RestAttachmentListManagerService,
		private rxStompService: RxStompService,
		@Inject(APP_CONFIG_TOKEN) public appConfig: ISaepIctAngularCoreAppConfig
  ) {
  }

  ngOnDestroy(): void {
    UtilDownload.downloadThroughIframeRemove(this.downloadThroughIframeId);
  }

/*
  async attachmentListAdd() {
    try {
      const attachmentListToAdd: AttachedFile[] =
        await this.utilAttachmentListManager.postAttachmentList(this.attachmentListToAdd, this._configuration);
      this.attachmentListchange.emit(this.returnAttachmentListAfterAdd(attachmentListToAdd));
      this.attachmentListToAdd = [];
      this.attachmentResolverService.empty();
      this.snackBar.open(
        this.translateService.instant('saep_ict_angular_core.attachment_list_manager.message.save'),
				'OK',
        { duration: 3000 }
			);
    } catch(err) {
      this.utilMatSnackBar.errorWith('saep_ict_angular_core.attachment_list_manager.message.error_with.attachmentListAdd');
      throw new Error(err);
    }
  }
  */

  attachmentListAdd() {
    Array.from(this.attachmentListToAdd).forEach((file: File) => {
      this.uploadService.addFileToUpload(file, this._configuration);
    });
    if (this.attachmentListToAdd?.length) {
      this.fileUpload();
    }
  }

  private fileUpload() {
    try {
      this.uploadService
        .startUpload()
        .pipe(finalize(() => {
          // console.info('complete saveEmitter');
          // this.saveEmitter.emit();
        }))
        .subscribe(v => {
          // console.info('complete file saveEmitter');
          // this.saveEmitter.emit();
          this.attachmentListchange.emit(this.returnAttachmentListAfterAdd([v]));
          this.attachmentListToAdd = [];
          this.attachmentResolverService.empty();
          this.utilMatSnackBar.snackBar.open(
            this.translateService.instant('saep_ict_angular_core.attachment_list_manager.message.save'),
            'OK',
            { duration: 3000 }
          );
        });
    } catch(err) {
      this.utilMatSnackBar.errorWith('saep_ict_angular_core.attachment_list_manager.message.error_with.attachmentListAdd');
      throw new Error(err);
    }
  }

	async attachmentListDelete(e: AttachedFile[]) {
    try {
//      await this.utilAttachmentListManager.deleteAttachmentList(e, this._configuration);
      await this.uploadService.deleteAttachmentList(e, this._configuration);
      this.attachmentListchange.emit(this.returnAttachmentListAfterRemove(e));
      this.utilMatSnackBar.snackBar.open(
        this.translateService.instant('saep_ict_angular_core.attachment_list_manager.message.delete'),
				'OK',
        { duration: 3000 }
			);
    } catch(err) {
      this.utilMatSnackBar.errorWith('saep_ict_angular_core.attachment_list_manager.message.error_with.attachmentListDelete');
      throw new Error(err);
    }
  }

  // attachment-resolver
  async attachmentResolverFileEmitter(e: File[]) {
    let fileNameDuplicate: string;
    if (
      !this._configuration.upload.fileNameAllowDuplicate &&
      this._configuration.localListHandler &&
      this._configuration.localListHandler.data &&
      this._configuration.localListHandler.data.length > 0
    ) {
      fileNameDuplicate = await UtilFile.isPresentDuplicate(e, this._configuration.localListHandler.data);
      if (fileNameDuplicate) {
        this.utilMatSnackBar.snackBar.open(
          `${this.translateService.instant('saep_ict_angular_core.attachment_list_manager.message.error.file_name_duplicate')} ${fileNameDuplicate}`,
          'OK'
        );
      }
    }
    const fileNameCharacterNotAccepted = await UtilFile.isPresentCharacterNotAccepted(e, this._configuration.upload.fileNameRegex);
    if (fileNameCharacterNotAccepted) {
      this.utilMatSnackBar.snackBar.open(
				`${this.translateService.instant('saep_ict_angular_core.attachment_list_manager.message.error.file_name_character_not_accepted')} ${this._configuration.upload.fileNameCharactersAccepted}`,
				'OK'
			);
    }
    if (fileNameDuplicate || fileNameCharacterNotAccepted) {
      this.attachmentResolverService.empty();
    } else {
      this.attachmentListToAdd = e;
      this.attachmentListAdd();
    }

  }

  // dialog
  dialogConfirmAttachmentListDelete(e: AttachedFile[]) {
    const title =
      this.sentencecasePipe.transform(
        this.translateService.instant("saep_ict_angular_core.attachment_list_manager.dialog.confirm_delete_title")
      );
    let text =
      `<strong style="color: #f44336">${
        this.sentencecasePipe.transform(
          this.translateService.instant("saep_ict_angular_core.attachment_list_manager.dialog.confirm_delete_body"))
        }</strong>`;
    text = text + "<ul>";
    e.forEach(i => {
      text = `${text}<li>${i.name}</li>`;
    });
    text = `${text}</ul>`;
		const dialogRef: MatDialogRef<DialogConfirmComponent> = this.matDialog.open(DialogConfirmComponent, {
			data: {
				title: title,
				text: text,
				panelClass: 'dialog-medium'
			}
		});
		dialogRef.afterClosed().subscribe(res => {
			if (res) {
        this.attachmentListDelete(e);
			}
		});
  }

  dialogPreviewOpen(e: AttachedFile) {
		const dialogRef: MatDialogRef<DialogImageComponent> = this.matDialog.open(DialogImageComponent, {
			data: {
				title: e.name,
				image: e.bucket_link_thumbnail
			},
			panelClass: ['michelangelo-theme-dialog']
		});
	}

  // misc
  returnAttachmentListAfterRemove(e: AttachedFile[]): AttachedFile[] {
    const attachmentList: AttachedFile[] =
      this.returnAttachmentListSaveMapClone(this._configuration.localListHandler.data);
    e.forEach(i => {
      const attachmentListIndex = this.utilService.getElementIndex(attachmentList, 'name', i.name);
      attachmentList.splice(attachmentListIndex, 1);
    });
    return attachmentList;
  }

  returnAttachmentListAfterAdd(e: AttachedFile[]): AttachedFile[] {
    const attachmentList: AttachedFile[] =
      this._configuration.localListHandler &&
      this._configuration.localListHandler.data &&
      this._configuration.localListHandler.data.length > 0 ?
      this.returnAttachmentListSaveMapClone(this._configuration.localListHandler.data):
      [];
    e = this.returnAttachmentListSaveMapClone(e);
    return e.concat(attachmentList);
  }

  setAttachmentListConfiguration(e: AttachmentListManager.Configuration<AttachedFile>) {
    if (
      e &&
      e.localListHandler &&
      e.localListHandler.data &&
      e.localListHandler.data.length > 0
    ) {
      const pathQueryParamSegment = e.pathQueryParam ? `/${e.pathQueryParam}/` : '/';
      const baseSegment =
        this.appConfig['envConfig'].bucketManager.be_url +
        '/' +
        e.pathUrl +
        '/' +
        'download-multipart?path=' +
        pathQueryParamSegment;
      const tokenSegment = '&token=' + this.appConfig.token;

      for (const attachment of e.localListHandler.data) {
        const fileName = attachment.nameOnBucket ? attachment.nameOnBucket : attachment.name;
        attachment.bucket_link =
          baseSegment +
          fileName +
          tokenSegment;
        attachment.bucket_link_thumbnail =
          baseSegment +
          AttachmentListManagerEnum.THUMBNAIL +
          '/' +
          this.returnAttachmentFileNameThumbnail(fileName) +
          tokenSegment;
      }
    }
    this._configuration = e;
  }

  returnAttachmentListSaveMapClone(e: AttachedFile[]): AttachedFile[] {
    e = _.cloneDeep(e);
    return e.map(i => {
      return {
        alt: i.name,
        name: i.name,
        nameOnBucket: i.nameOnBucket,
        date_creation: i.date_creation
      }
    });
  }

  returnAttachmentFileNameThumbnail(e: string): string {
    try {
      const pos = e.lastIndexOf(".");
      return e.substring(0, pos < 0 ? e.length : pos) + ".jpg";
    } catch(err) {
      throw new Error(err);
    }
  }

  handleDownloadFromListSelected(list: AttachedFile[]) {
    if (
      list &&
      (
        list.length === 0 ||
        list.length > 0
      )
    ) {
      switch (list.length) {
        case 0:
          // nessun elemento da scaricare
          break;
        case 1:
          const pathUrl = this._configuration.pathUrl ? `/${this._configuration.pathUrl}/` : '/';
          const pathQueryParam = this._configuration.pathQueryParam ? `/${this._configuration.pathQueryParam}/` : '/';
          const name = list[0].nameOnBucket ? list[0].nameOnBucket : list[0].name;
          const url =
            this.appConfig['envConfig'].bucketManager.be_url +
            pathUrl +
            'download-multipart?path=' +
            pathQueryParam +
            name +
            '&token=' +
            this.appConfig.token;
          UtilDownload.handleDownloadThroughIframe(url, this.downloadThroughIframeId);
          break;
        default:
          this.downloadArchiveZip(list);
          break;
      }
    }
  }

	async downloadArchiveZip(itemListSelected: AttachedFile[]) {
    const nameList = itemListSelected.map(i => i.nameOnBucket ? i.nameOnBucket : i.name);
    let pathListSting = '';
    const pathQueryParamSegment = this._configuration.pathQueryParam ? `/${this._configuration.pathQueryParam}/` : '/';
    for (let i = 0; i < nameList.length; i++) {
      pathListSting += pathQueryParamSegment + nameList[i];
      if (i !== nameList.length - 1) {
        pathListSting += ',';
      }
    }
    const message: WebSocketMessageModel<never, string> = {
      type: WebSocketChannelTypeCodeEnum.ARCHIVE_ZIP_DOWNLOAD,
      message: {
        header: {
          id: this.utilService.guid(),
          action: WebSocketActionEnum.START_PROCESS,
          state: WebSocketTopicStateEnum.REQUESTED,
          progress: 0
        }
      }
    };
    await this.rxStompService.connect<AttachmentListManagerComponent, void, string>(
      this,
      message,
      {
        COMPLETED: this.downloadArchiveSimulatingClick,
        ERROR: this.downloadArchiveWebsocketError
      }
    );
    this.restAttachmentListService.downloadZip(
      {
        path: this._configuration.pathUrl
      },
      {
        pathList: pathListSting,
        topic: message.message.header.id,
        token: this.appConfig.token,
      }
    );
	}

	downloadArchiveSimulatingClick(
		context: AttachmentListManagerComponent,
		message: WebSocketMessageModel<never, string>
	  ) {
		const pathUrl =
			context._configuration.pathUrl ?
			`/${context._configuration.pathUrl}/` :
			'/';
		const url =
			context.appConfig['envConfig'].bucketManager.be_url +
			pathUrl +
			'download-multipart?path=' +
			message.message.data +
			'&remove=true&token=' +
			context.appConfig.token;
		context.utilDownload.handleDownloadThroughIframe(url, context.downloadThroughIframeId);
	}

	downloadArchiveWebsocketError(context: AttachmentListManagerComponent) {
		context.utilService.loadSnackbarWithAsyncTranslatedAction(
			'mc.error.websocket_generic_fail',
			'mc.general.ok',
			5000
		);
	}

}
