export enum PermissionKeyEnum {
	AGENT_CONTACT_DETAIL = 'agent-contact-detail',
	AGENT_CONTACT_LIST = 'agent-contact-list',
	AGENT_DASHBOARD = 'agent-dashboard',
	AGENT_INVOICE_DETAIL = 'agent-invoice-detail',
	AGENT_INVOICE_LIST = 'agent-invoice-list',
	AGENT_MEDIACENTER = 'agent-mediacenter',
	AGENT_ORDER_DETAIL = 'agent-order-detail',
	AGENT_ORDER_LIST = 'agent-order-list',
	AGENT_ORGANIZATION_CREDIT = 'agent-organization-credit',
	AGENT_ORGANIZATION_DASHBOARD = 'agent-organization-dashboard',
	AGENT_ORGANIZATION_DESTINATION = 'agent-organization-destination',
	AGENT_ORGANIZATION_CONTACT = 'agent-organization-contact',
	AGENT_ORGANIZATION_DETAIL = 'agent-organization-detail',
	AGENT_ORGANIZATION_FULL_DETAIL = 'agent-organization-full-detail',
	AGENT_ORGANIZATION_LIST = 'agent-organization-list',
	AGENT_ORGANIZATION_COMMENT = 'agent-organization-comment',
	AGENT_PROFILE = 'agent-profile',
	AGENT_REPORTS = 'agent-reports',
	AGENT_RECEIPT = 'agent-receipts',
	AGENT_TICKET_CENTER = 'agent-ticket-center',
	B2B_ASSET_REQUEST_CENTER = 'b2b-asset-request-center',
	B2B_CHAT = 'b2b-chat',
	B2B_CONTACT_DETAIL = 'b2b-contact-detail',
	B2B_CONTACT_LIST = 'b2b-contact-list',
	B2B_DASHBOARD = 'b2b-dashboard',
	B2B_INVOICE_DETAIL = 'b2b-invoice-detail',
	B2B_INVOICE_LIST = 'b2b-invoice-list',
	B2B_MEDIACENTER = 'b2b-mediacenter',
	B2B_NEWS = 'b2b-news',
	B2B_ORDER_DETAIL = 'b2b-order-detail',
	B2B_ORDER_LIST = 'b2b-order-list',
	B2B_ORGANIZATION_CONTACT = 'b2b-organization-contact',
	B2B_ORGANIZATION_CREDIT = 'b2b-organization-credit',
	B2B_ORGANIZATION_DASHBOARD = 'b2b-organization-dashboard',
	B2B_ORGANIZATION_DESTINATION = 'b2b-organization-destination',
	B2B_ORGANIZATION_DETAIL = 'b2b-organization-detail',
	B2B_ORGANIZATION_FULL_DETAIL = 'b2b-organization-full-detail',
	B2B_ORGANIZATION_LIST = 'b2b-organization-list',
	B2B_ORGANIZATION_COMMENT = 'b2b-organization-comment',
	B2B_PROFILE = 'b2b-profile',
	B2B_REPORTS = 'b2b-reports',
	B2B_SURVEY_CENTER = 'b2b-survey-center',
	B2B_SURVEY_CENTER_BUDGET = 'b2b-survey-center-budget',
	B2B_SURVEY_CENTER_FORECAST = 'b2b-survey-center-forecast',
	B2B_SURVEY_CENTER_MEDIA_MARKETING = 'b2b-survey-center-media-marketing',
	B2B_TICKET_CENTER = 'b2b-ticket-center',
	B2C_CONTACT_DETAIL = 'b2c-contact-detail',
	B2C_CONTACT_LIST = 'b2c-contact-list',
	B2C_DASHBOARD = 'b2c-dashboard',
	B2C_INVOICE_DETAIL = 'b2c-invoice-detail',
	B2C_INVOICE_LIST = 'b2c-invoice-list',
	B2C_MEDIACENTER = 'b2c-mediacenter',
	B2C_MY_ACCOUNT = 'b2c-my-account',
	B2C_ORDER_DETAIL = 'b2c-order-detail',
	B2C_ORDER_LIST = 'b2c-order-list',
	B2C_ORGANIZATION_DESTINATION = 'b2c-organization-destination',
	B2C_ORGANIZATION_DETAIL = 'b2c-organization-detail',
	B2C_ORGANIZATION_FULL_DETAIL = 'b2c-organization-full-detail',
	B2C_ORGANIZATION_COMMENT = 'b2c-organization-comment',
	B2C_PROFILE = 'b2c-profile',
	B2C_TICKET_CENTER = 'b2c-ticket-center',
	BACKOFFICE_AGENCY_ASSET_REQUEST_CENTER = 'backoffice-agency-asset-request-center',
	BACKOFFICE_AGENCY_PRINT_PRODUCTION_FLOW_CENTER = 'backoffice-agency-print-production-flow-center',
	BACKOFFICE_ARTICLE_CONFIGURATION_DETAIL = 'backoffice-article-configuration-detail',
	BACKOFFICE_ARTICLE_CONFIGURATION_LIST = 'backoffice-article-configuration-list',
	BACKOFFICE_FAMILY_CONFIGURATION_LIST = 'backoffice-family-configuration-list',
	BACKOFFICE_ASSET_REQUEST_CENTER = 'backoffice-asset-request-center',
	BACKOFFICE_CHAT = 'backoffice-chat',
	BACKOFFICE_CATEGORY_CONFIGURATION_DETAIL = 'backoffice-category-configuration-detail',
	BACKOFFICE_CATEGORY_CONFIGURATION_LIST = 'backoffice-category-configuration-list',
	BACKOFFICE_CONTACT_DETAIL = 'backoffice-contact-detail',
	BACKOFFICE_CONTACT_LIST = 'backoffice-contact-list',
	BACKOFFICE_CONTEXT_CODE_MANAGEMENT_DETAIL = 'backoffice-context-code-management-detail',
	BACKOFFICE_CONTEXT_CODE_MANAGEMENT_LIST = 'backoffice-context-code-management-list',
	BACKOFFICE_DASHBOARD = 'backoffice-dashboard',
	BACKOFFICE_DATA_CONFIGURATION = 'backoffice-data-configuration',
	BACKOFFICE_INFORMATIVE_PAGE_DETAIL = 'backoffice-informative-page-detail',
	BACKOFFICE_INFORMATIVE_PAGE_LIST = 'backoffice-informative-page-list',
	BACKOFFICE_INVOICE_DETAIL = 'backoffice-invoice-detail',
	BACKOFFICE_INVOICE_LIST = 'backoffice-invoice-list',
	BACKOFFICE_MEDIACENTER = 'backoffice-mediacenter',
	BACKOFFICE_NEWS = 'backoffice-news',
	BACKOFFICE_ORDER_DETAIL = 'backoffice-order-detail',
	BACKOFFICE_ORDER_LIST = 'backoffice-order-list',
	BACKOFFICE_ORGANIZATION_CREDIT = 'backoffice-organization-credit',
	BACKOFFICE_ORGANIZATION_DASHBOARD = 'backoffice-organization-dashboard',
	BACKOFFICE_ORGANIZATION_DESTINATION = 'backoffice-organization-destination',
	BACKOFFICE_ORGANIZATION_CONTACT = 'backoffice-organization-contact',
	BACKOFFICE_ORGANIZATION_DETAIL = 'backoffice-organization-detail',
	BACKOFFICE_ORGANIZATION_FULL_DETAIL = 'backoffice-organization-full-detail',
	BACKOFFICE_ORGANIZATION_LIST = 'backoffice-organization-list',
	BACKOFFICE_PRINT_PRODUCTION_FLOW_CENTER = 'backoffice-print-production-flow-center',
	BACKOFFICE_ORGANIZATION_COMMENT = 'backoffice-organization-comment',
	BACKOFFICE_PRODUCT_STOCK = 'backoffice-product-stock',
	BACKOFFICE_PROFILE = 'backoffice-profile',
	BACKOFFICE_REGISTRATION_REQUEST_DETAIL = 'backoffice-registration-request-detail',
	BACKOFFICE_REGISTRATION_REQUEST_LIST = 'backoffice-registration-request-list',
	BACKOFFICE_REPORTS = 'backoffice-reports',
	BACKOFFICE_SURVEY_CENTER = 'backoffice-survey-center',
	BACKOFFICE_SURVEY_CENTER_BUDGET = 'backoffice-survey-center-budget',
	BACKOFFICE_SURVEY_CENTER_MEDIA_MARKETING = 'backoffice-survey-center-media-marketing',
	BACKOFFICE_TICKET_CENTER = 'backoffice-ticket-center',
	BACKOFFICE_USER_MANAGEMENT_DETAIL = 'backoffice-user-management-detail',
	BACKOFFICE_USER_MANAGEMENT_LIST = 'backoffice-user-management-list',
	BACKOFFICE_ADDITIONAL_SERVICE_CONFIGURATION = 'backoffice-additional-service-configuration',
	CRM_CONTACT_DETAIL = 'crm-contact-detail',
	CRM_CONTACT_LIST = 'crm-contact-list',
	CRM_CRM = 'crm-crm',
	CRM_DASHBOARD = 'crm-dashboard',
	CRM_INVOICE_DETAIL = 'crm-invoice-detail',
	CRM_INVOICE_LIST = 'crm-invoice-list',
	CRM_OFFER_DETAIL = 'crm-offer-detail',
	CRM_OFFER_LIST = 'crm-offer-list',
	CRM_OPPORTUNITY_ACTIVITY = 'crm-opportunity-activity',
	CRM_OPPORTUNITY_COMMENT = 'crm-opportunity-comment',
	CRM_OPPORTUNITY_DETAIL = 'crm-opportunity-detail',
	CRM_OPPORTUNITY_LIST = 'crm-opportunity-list',
	CRM_ORGANIZATION_CREDIT = 'crm-organization-credit',
	CRM_ORGANIZATION_DESTINATION = 'crm-organization-destination',
	CRM_ORGANIZATION_DETAIL = 'crm-organization-detail',
	CRM_ORGANIZATION_FULL_DETAIL = 'crm-organization-full-detail',
	CRM_ORGANIZATION_LIST = 'crm-organization-list',
	CRM_ORGANIZATION_COMMENT = 'crm-organization-comment',
	CRM_PROFILE = 'crm-profile',
	PORTAL_DASHBOARD = 'portal-dashboard',
	PORTAL_MEDIACENTER = 'portal-mediacenter',
	PORTAL_ORDER_DETAIL = 'portal-order-detail',
	PORTAL_ORDER_LIST = 'portal-order-list',
	PORTAL_ORGANIZATION_DESTINATION = 'portal-organization-destination',
	PORTAL_ORGANIZATION_DETAIL = 'portal-organization-detail',
	PORTAL_ORGANIZATION_FULL_DETAIL = 'portal-organization-full-detail',
	PORTAL_ORGANIZATION_COMMENT = 'portal-organization-comment',
	PORTAL_PROFILE = 'portal-profile',
	PORTAL_TICKET_CENTER = 'portal-ticket-center'
}
