import { Injector } from '@angular/core';
import { ITdDataTableColumn } from '@covalent/core/data-table';
import { FileSizePipe } from '../shared/pipe/file-size.pipe';
import moment from 'moment';

const injector = Injector.create({
	providers: [
		{ provide: FileSizePipe, deps: [] },
	]
});
const fileSizePipe = injector.get(FileSizePipe);

export const homeColumnList: (ITdDataTableColumn & { trans_label_key })[] = [
  {
    name: 'name',
    sortable: true,
    label: 'data_property.name',
    trans_label_key: 'mc.data_property.name',
  },
  {
    name: 'dateUpdate',
    label: 'data_property.date_update',
    trans_label_key: 'mc.data_property.date_update',
    format: (v: number) => moment(v).format('DD/MM/YYYY'),
    width: 120,
  },
  {
    name: 'fileType',
    sortable: true,
    label: 'data_property.file_type',
    trans_label_key: 'mc.data_property.file_type',
    width: 90,
  },
  {
    name: 'size',
    label: 'data_property.file_size',
    trans_label_key: 'mc.data_property.file_size',
    format: (value: any) => value ? fileSizePipe.transform(value) : '',
    width: 80,
  },
  {
    name: 'opzioni',
    label: null,
    trans_label_key: null,
    sortable: false,
    width: 85,
  }
];
