import { TitleCasePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import {
	OrderPouchModel,
	OrderStatusEnum,
	DivisionPouchModel,
	OrganizationPouchModel,
	ArticleDescriptionItem,
	ArticlePouchModel,
	OrganizationTypeEnum,
	AddressPouchModel,
	DiscountTypeEnum
} from '@saep-ict/pouch_agent_models';
import {
	BaseStateModel,
	BaseState,
	SentencecasePipe,
	DataSetting,
	DialogConfirmComponent
} from '@saep-ict/angular-core';
import {
	ArticleChangeContextModel,
	ArticleChangeContextResponseModel,
	OrderHeaderObjectBodyTablePouchModel,
	UpdateOrder$
} from '../../model/order-util.model';
import { ArticleRecap } from '../../model/state/article-list-state.model';
import { ConfigurationViewModel } from '../../model/configuration.model';
import * as ConfigurationCustomerOrder from '../../constants/configuration-customer/order/order.constant';
import { Store } from '@ngrx/store';
import { StateFeature } from '../../state';
import { OrderActionEnum, OrderStateAction } from '../../state/order/order.actions';
import _ from 'lodash';
import { Observable, Subject, timer } from 'rxjs';
import { debounce, filter, map } from 'rxjs/operators';
import { AppUtilService } from './app-util.service';
import { UtilPriceService } from './util-price.service';
import { MatDialog } from '@angular/material/dialog';
import { LocalStorageService } from 'ngx-webstorage';
import {
	ContextApplicationItemCodeEnum,
	UserDetailModel,
	AuxiliaryTableStateModel,
	OrderStateModel,
	ExtraFieldOrderHeaderPouchModel,
	OrderRowModel,
	AngularSpin8CoreUtilTranslateService
} from '@saep-ict/angular-spin8-core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { OrderCloneTypeEnum } from '../../enum/order.enum';
import { StoreUtilService } from './store-util.service';
import { TranslateService } from '@ngx-translate/core';
import { DialogActionsComponent } from '../../widget/dialog/dialog-actions/dialog-actions.component';
import { MatSnackBarWrapperComponent } from '../../widget/mat-snack-bar-wrapper/mat-snack-bar-wrapper.component';
import { CalcSheetFile, CalcSheetItem } from '../../model/calc-sheet.model';
import * as calcSheet from '../../constants/calc-sheet.constants';
import * as util from '../../constants/util.constants';
import { CurrencyPouchModel } from '@saep-ict/pouch_agent_models';
import * as ConfigurationCustomerArticleAvailability from '../../constants/configuration-customer/article/article-availability.constant';
import * as ConfigurationCustomerOrderOrganization from '../../constants/configuration-customer/order/order-organization.constant';
import * as ConfigurationCustomerOrderHeaderSetting from '../../constants/configuration-customer/order/order-header-setting.constant';
import * as ConfigurationCustomerGtmOrder from '../../constants/configuration-customer/gtm/gtm-order.constant';
import moment from "moment/moment";

@Injectable({
	providedIn: 'root'
})
export class UtilOrderService {
	user$: Observable<BaseStateModel<UserDetailModel>> = this.store.select(StateFeature.getUserState);
	user: UserDetailModel;

	order$: Observable<BaseStateModel<OrderStateModel>> = this.store.select(StateFeature.getOrderState);

	auxiliaryTable$: Observable<BaseStateModel<AuxiliaryTableStateModel>> = this.store.select(
		StateFeature.getAuxiliaryTableState
	);
	auxiliaryTable: AuxiliaryTableStateModel;

	articleList$: Observable<BaseStateModel<ArticlePouchModel[]>> = this.store.select(StateFeature.getArticleList);
	articleDescription$: Observable<BaseStateModel<ArticleRecap>> = this.store.select(
		StateFeature.getArticleDescription
	);
	// TODO: riallineare i modelli in modo sia presente in article code_item
	articleDescription;

	orderChangeTemp: OrderStateModel;
	updateOrderSource = new Subject<UpdateOrder$>();
	updateOrder$: Observable<UpdateOrder$> = this.updateOrderSource.asObservable();

	orderHeaderObjectBodyTablePouchModelList: OrderHeaderObjectBodyTablePouchModel[];

	/**
	 * La variabile evita il passaggio presso i metodi del map di `this.categoryList$` (order-detail-catalog,
	 * order-detail-checkout), i quali determinano il totale refresh dell'alberatura di categorie e
	 * dei prodotti in essa contenuti. L'evento genera a sua volta un reset dello scroll, facendo
	 * perdere il punto di lavoro.
	 *
	 * Solo l'eliminazione di un articolo inverte la variabile, consentendo alla schermata di essere ristabilità a partire dall'ordine
	 * aggiornato, in arrivo dalla sua store UPDATE. Si veda onArticleChange().
	 *
	 * @memberof OrderDetailCheckoutComponent
	 */
	updateArticle;

	/**
	 * La variabile permette la disabilitazione del button di conferma a livello di view, in modo da minimizzare il rischio
	 * di conflitti durante il flusso di salvataggio ordine. Inibisce:
	 * - `OrderDetailAsideSummaryComponent` invio ordine presso
	 * - `OrderDetailMainHeaderEditComponent` passaggio a `OrderDetailCatalogComponent`
	 *
	 * @memberof UtilOrderService
	 */
	orderDisabledViewConfirm = false;
	/**
	 * Se `true` disabilita la chiamata di salvataggio ordine, pur continuando a permettere l'aggiornamento di
	 * `orderChangeTemp`
	 *
	 * @memberof UtilOrderService
	 */
	orderDisabledSavePut = false;
	/**
	 * Restituisce `true` in caso siano state fatte ulteriori modifiche nel periodo di disablitazione della chiamata
	 * di salvtaggio ordine (`orderDisabledSavePut`).
	 * 
	 * @memberof UtilOrderService
	 */
	orderDisabledSavePutDelta = false;

	constructor(
		private utilService: AppUtilService,
		private titlecasePipe: TitleCasePipe,
		private store: Store,
		private utilPriceService: UtilPriceService,
		private dialog: MatDialog,
		private localStorageService: LocalStorageService,
		private utilTranslateService: AngularSpin8CoreUtilTranslateService,
		private sentencecasePipe: SentencecasePipe,
		private snackBar: MatSnackBar,
		private utilStoreService: StoreUtilService,
		private sentenceCasePipe: SentencecasePipe,
		private translate: TranslateService
	) {
		this.subscribeData();
	}

	subscribeData() {
		this.user$.pipe().subscribe(res => {
			this.user = res ? res.data : null;
		});
		this.auxiliaryTable$.pipe().subscribe(res => {
			if (res && res.data) {
				this.auxiliaryTable = res.data;
				this.orderHeaderObjectBodyTablePouchModelList = [
					{
						keyCode: 'order_causal',
						keyObject: 'order_causal_object',
						auxiliaryTableList: this.auxiliaryTable.causalHeaderSoList
					},
					{
						keyCode: 'payment_code',
						keyObject: 'payment_object',
						auxiliaryTableList: this.auxiliaryTable.paymentList
					},
					{
						keyCode: 'shipping_method_code',
						keyObject: 'shipping_method_object',
						auxiliaryTableList: this.auxiliaryTable.methodShippingList
					}
				];
			}
		});
		this.articleDescription$.pipe().subscribe(articleDescription => {
			this.articleDescription = articleDescription ? articleDescription.data : null;
		});
		this.updateOrder$.pipe(
			map(e => {
				// ad ogni modifica sull'ordine vengono inibiti i button di conferma nelle view che consentono
				// invio ordine, uscita su altra schermata, ecc
				this.orderDisabledViewConfirm = true;
				// viene notificato che il flusso di salvataggio ordine è in corso, momentaneamente commentato per rilascio v3, da valutare eliminazione per sempre
				/* this.snackBar.open(
					this.sentencecasePipe.transform(
						`${this.utilTranslateService.translate.instant('order.saving')}...`
					)
				); */
				return e;
			}),
			debounce(e =>
				timer(
					e.debounceTime || e.debounceTime === 0 ?
					e.debounceTime :
					2000
				)
			)
		).subscribe((e: UpdateOrder$) => {
			if (this.user) {
				// la chiamate di salvataggio sono disabilitate fino al termine della chiamata di salvataggio
				// in corso
				if (!this.orderDisabledSavePut) {
					// qualsiasi modifica ordine da parte di utente noto
					if (e.statusChange) {
						// modifica al solo order.header.status (invio, autorizzazione backoffice)
						if (
							e.order && e.order.product_list && e.order.product_list.length > 0 &&
							ConfigurationCustomerOrder.canUpdateToStatus[this.user.current_permission.context_application]
							.includes(e.order.header.status)
						) {
							this.orderUpdateHandler(e);
						}
					} else {
						// modifica ordine di altro genere
						if (
							ConfigurationCustomerOrder.canEditByStatus[this.user.current_permission.context_application]
							.includes(e.order.header.status)
						) {
							this.orderUpdateHandler(e);
						}
					}
				} else {
					// tracciamento delle modifiche avvenute da parte dell'utente nel periodo in cui
					// `orderDisabledSavePut`
					this.orderDisabledSavePutDelta = true;
				}
			} else {
				// qualsiasi modifica ordine da parte di utente anonimo B2C: nessun controllo privilegi
				this.orderUpdateHandler(e);
			}
		});
		this.order$.pipe(
			filter(
				(e: BaseStateModel<OrderPouchModel<ExtraFieldOrderHeaderPouchModel>>) => !!(e && e.type)
			),
			map((e: BaseStateModel<OrderStateModel>) => {
				switch (e.type) {
					case OrderActionEnum.DISABLE_SAVE_PUT: {
						// `DISABLE_SAVE_PUT` viene lanciato prima di ogni salvataggio, consentendo di rinnovare
						// la disabilitazione dei button di conferma nelle view
						this.orderDisabledViewConfirm = true;
						// e potenziali chiamate di salvataggio successive, potenzialmente concorrenziali
						this.orderDisabledSavePut = true;
						break;
					}
					case OrderActionEnum.ERROR:
					case OrderActionEnum.ERROR_409: 
						this.orderDisabledViewConfirm = false;
						break;
					case OrderActionEnum.UPDATE:
						// le successive chiamate di salvataggio sono riabilitate
						this.orderDisabledSavePut = false;
						if (this.orderChangeTemp && e.data._rev) {
							this.orderChangeTemp._rev = e.data._rev;
						}
						if (this.orderDisabledSavePutDelta) {
							// in caso siano presenti ulteriori modifiche all'ordine avvenute durante la
							// disabilitazione, viene resettato il boolean che le indica
							this.orderDisabledSavePutDelta = false;
							// viene effettuata una ulteriore save per mandare in remoto le modifiche residue
							this.updateOrderSource.next({
								order: this.orderChangeTemp,
								useLoader: false
							});
						} else {
							if (this.orderDisabledViewConfirm) {
								// in caso non siano presenti ulteriori modifiche, viene resettato il boolean che inibisce
								// le conferme in generale nelle view (cambi di stato, uscite, ecc)
								this.orderDisabledViewConfirm = false;
								// viene scatenata messaggistica di conferma salvataggio ordine
								this.snackBar.open(
									this.sentencecasePipe.transform(
										this.utilTranslateService.translate.instant('order.save')
									),
									'OK',
									{ duration: 3000 }
								);
							}
						}
						break;
				}
			})
		).subscribe();
	}

	orderUpdateHandler(e: UpdateOrder$) {
		const dataSetting: DataSetting = {};
		dataSetting.useLoader = e.useLoader;
		this.store.dispatch(OrderStateAction.disableSavePut());
		this.store.dispatch(OrderStateAction.save(new BaseState(_.cloneDeep(e.order), dataSetting)));
	}

	addArticleToOrder(article: ArticlePouchModel, order: OrderStateModel): OrderStateModel {
		ConfigurationCustomerGtmOrder.trackArticleAddedToCart(article, order);	 
		if (article.input_quantity) {
			const productIndex = this.utilService.getElementIndex(order.product_list, 'code_item', article.code_item);
			const articleReturn: ArticlePouchModel = this.returnArticleForOrderSave(article);
			if (productIndex || productIndex === 0) {
				order.product_list[productIndex] = articleReturn;
			} else {
				order.product_list.push(articleReturn);
			}
		}
		return order;
	}

	returnArticleForOrderSave(article: ArticlePouchModel): ArticlePouchModel {
		const articleReturn: ArticlePouchModel = {
			code_erp: article.code_erp,
			code_item: article.code_item,
			description: article.description,
			discount_agent: article.discount_agent,
			discount: article.articlePrice.discount,
			note_order: article.note_order,
			ordered_quantity: article.input_quantity,
			price: article.articlePrice.price,
			qty_free: article.qty_free > article.input_quantity ? article.input_quantity : article.qty_free,
			vat: article.articlePrice.vat,
			// TODO: ripristinare la selezione di queste voci in maniera dinamica, deprecata tempo addietro qui sotto..
			articleDescription: {
				weight: article.articleDescription.weight ? article.articleDescription.weight : 0,
				category_list: null,
				is_highlighted: null,
				language_list: null,
				related_article_list: null,
				related_kit_list: null
			}
		};
		articleReturn.articleDescription = this.utilService.deleteEmptyProperties(articleReturn.articleDescription);
		// vengono aggiunte all'articolo salvato le proprietà utili al funzionamento dell'order detail in modalità
		// 'screenshot'
		// for (let i = 0; i < ConfigurationArticle.RecapDescription.propertyToBypass.length; i++) {
		// 	articleReturn.articleDescription[ConfigurationArticle.RecapDescription.propertyToBypass[i]] =
		// 		article.articleDescription[ConfigurationArticle.RecapDescription.propertyToBypass[i]];
		// }
		return this.utilService.deleteEmptyProperties(articleReturn);
	}

	removeProductFromOrder(code: string, order: OrderStateModel): OrderStateModel {
		const productIndex = this.utilService.getElementIndex(order.product_list, 'code_item', code);
		if (productIndex || productIndex === 0) {
			order.product_list.splice(productIndex, 1);
		}
		return order;
	}

	/**
	 * Il metodo restituisce una lista articoli aggiornata con le informazioni recuperate dagli eventuali articoli presenti nell'ordine
	 *
	 * @param {ArticlePouchModel[]} articleList
	 * @param {OrderPouchModel<ExtraFieldOrderHeaderPouchModel>} order
	 * @returns {ArticlePouchModel[]}
	 * @memberof UtilOrderService
	 */
	mergeProductDetailInArticle(
		articleList: ArticlePouchModel[],
		order: OrderPouchModel<ExtraFieldOrderHeaderPouchModel>
	): ArticlePouchModel[] {
		articleList = _.cloneDeep(articleList);
		if (order.product_list && order.product_list.length) {
			for (const article of order.product_list) {
				const articleIndex: number = this.utilService.getElementIndex(
					articleList,
					'code_item',
					article.code_item
				);
				if (articleIndex || articleIndex === 0) {
					articleList[articleIndex].ordered_quantity = article.ordered_quantity;
					articleList[articleIndex].discount_agent = article.discount_agent;
					articleList[articleIndex].qty_free = article.qty_free;
					articleList[articleIndex].note_order = article.note_order;
					articleList[articleIndex].input_quantity = article.ordered_quantity;
					articleList[articleIndex].calculate_price = this.utilPriceService.retrieveTotalArticlePrice(
						articleList[articleIndex]
					);
				} else {
					const articleRelatedTester: ArticlePouchModel = articleList.find(
						i =>
							i.articleDescription &&
							i.articleDescription.relatedArticleTester &&
							i.articleDescription.relatedArticleTester.code_item &&
							i.articleDescription.relatedArticleTester.code_item === article.code_item
					);
					if (articleRelatedTester) {
						articleRelatedTester.articleDescription.relatedArticleTester.ordered_quantity =
							article.ordered_quantity;
						articleRelatedTester.articleDescription.relatedArticleTester.input_quantity =
							article.ordered_quantity;
					}
				}
			}
		}
		return articleList;
	}

	/**
	 * Metodo master per gestire le modifiche dei campi dell'articolo con i quali l'utente può interagire.
	 * Determina la modifica del corrispettivo elemento presente nell'ordine, senza effetti diretti sulle eventuali
	 * liste di articoli esterne coinvolte (order-detail-catalog)
	 *
	 * @param {OrderRowModel} data
	 * @param {OrderStateModel} order
	 * @param {AuxiliaryTableStateModel} auxiliaryTable
	 * @param {OrganizationPouchModel} organization
	 * @memberof UtilOrderService
	 */
	async changeOrderArticle(
		data: OrderRowModel,
		order: OrderStateModel,
		organization: OrganizationPouchModel,
		useLoader: boolean,
		debounceTime?: number
	) {
		this.orderChangeTemp = this.orderChangeTemp ? this.orderChangeTemp : order;
		debounceTime =
			data.key === 'delete' || data.key === 'note_order' ?
			0 :
			debounceTime;
		let inputValue: number;
		if (data.key !== 'note_order') {
			inputValue =
				data.event.target.value === '' || data.key === 'delete'
					? 0
					: parseFloat(data.event.target.value.replace(',', '.'));
			inputValue =
				ConfigurationCustomerArticleAvailability.returnArticleQuantityByQtyBoxMultiple(
					inputValue,
					data.row,
					organization
				);
		}
		const request: ArticleChangeContextModel = {
			value: inputValue,
			article: data.row,
			order: this.orderChangeTemp
		};
		const changeMethodList = {
			discount_agent: { method: () => this.changeDiscountAgent(request) },
			input_quantity: { method: () => this.changeQuantity(request) },
			last_price: { method: () => this.changeLastPrice(request) },
			delete: { method: () => this.changeQuantity(request) },
			note_order: { method: () => this.changeNote(request) },
			qty_free: { method: () => this.changeQuantityFree(request) },
			input_quantity_related_tester: { method: () => this.changeInputQuantityRelatedTester(request) }
		};
		const contextResponse: ArticleChangeContextResponseModel = changeMethodList[data.key].method();
		if (contextResponse) {
			this.updateOrderProductArticleDescription(contextResponse);
			ConfigurationCustomerOrderHeaderSetting.updateOrderWeight(contextResponse.order);
			contextResponse.order = await this.utilPriceService.updatePriceOrder(contextResponse.order, organization);
			this.updateOrderSource.next({
				order: contextResponse.order,
				useLoader: useLoader,
				debounceTime: debounceTime
			});
		}
	}

	updateOrderProductArticleDescription(data: ArticleChangeContextResponseModel) {
		data.order.product_list.forEach(product => {
			if (!product.articleDescription) {
				product.articleDescription = <ArticleDescriptionItem>{};
			}
			// Conversion
			if (!product.articleDescription.conversion) {
				// TODO: senza questa condizione falliva sistematicamente l'eliminazione dell'articolo
				// da verificare il metodo in toto
				if (data.currentArticle) {
					const mainDivision = this.utilService.returnIsMainOfList<DivisionPouchModel>(
						data.currentArticle.division_list
					);
					if (mainDivision && Object.prototype.hasOwnProperty.call(mainDivision, 'conversion')) {
						product.articleDescription.conversion = mainDivision.conversion;
					}
				}
			}
			// Weight
			if (!product.articleDescription.weight) {
				// TODO: vedi su
				if (data.currentArticle) {
					product.articleDescription.weight = data.currentArticle.articleDescription.weight || 0;
				}
			}
		});
	}

	// util legati a changeOrderArticle
	changeQuantity(acc: ArticleChangeContextModel): ArticleChangeContextResponseModel | boolean {
		let response: ArticleChangeContextResponseModel = {};
		if (acc.value === 0) {
			response.order = <OrderStateModel>this.removeProductFromOrder(acc.article.code_item, acc.order);
			return response;
		} else {
			const currentArticle: ArticlePouchModel = _.cloneDeep(acc.article);
			currentArticle.input_quantity = acc.value;
			currentArticle.price = currentArticle.articlePrice.price;
			response = {
				currentArticle: currentArticle,
				order: <OrderStateModel>this.addArticleToOrder(currentArticle, acc.order)
			};
			return response;
		}
	}

	changeLastPrice(acc: ArticleChangeContextModel): ArticleChangeContextResponseModel | boolean {
		const currentArticle: ArticlePouchModel = _.cloneDeep(acc.article);
		currentArticle.price = acc.value;
		if (!currentArticle.input_quantity) {
			return false;
		}
		const response: ArticleChangeContextResponseModel = {
			currentArticle: currentArticle,
			order: <OrderStateModel>this.addArticleToOrder(currentArticle, acc.order)
		};
		return response;
	}

	changeQuantityFree(acc: ArticleChangeContextModel): ArticleChangeContextResponseModel | boolean {
		const currentArticle: ArticlePouchModel = _.cloneDeep(acc.article);
		currentArticle.qty_free = acc.value;
		const response: ArticleChangeContextResponseModel = {
			currentArticle: currentArticle,
			order: <OrderStateModel>this.addArticleToOrder(currentArticle, acc.order)
		};
		return response;
	}

	changeInputQuantityRelatedTester(acc: ArticleChangeContextModel): ArticleChangeContextResponseModel | boolean {
		let response: ArticleChangeContextResponseModel = {};
		if (acc.value === 0) {
			response.order = <OrderStateModel>(
				this.removeProductFromOrder(acc.article.articleDescription.relatedArticleTester.code_item, acc.order)
			);
			return response;
		} else {
			const currentArticle: ArticlePouchModel = _.cloneDeep(acc.article.articleDescription.relatedArticleTester);
			currentArticle.input_quantity = acc.value;
			currentArticle.price = currentArticle.articlePrice.price;
			response = {
				currentArticle: currentArticle,
				order: <OrderStateModel>this.addArticleToOrder(currentArticle, acc.order)
			};
			return response;
		}
	}

	changeDiscountAgent(acc: ArticleChangeContextModel): ArticleChangeContextResponseModel | boolean {
		const currentArticle: ArticlePouchModel = _.cloneDeep(acc.article);
		currentArticle.discount_agent = { value: acc.value, type: DiscountTypeEnum.percentage };
		const response: ArticleChangeContextResponseModel = {
			currentArticle: currentArticle,
			order: <OrderStateModel>this.addArticleToOrder(currentArticle, acc.order)
		};
		return response;
	}

	changeNote(acc: ArticleChangeContextModel): ArticleChangeContextResponseModel | boolean {
		const currentArticle: ArticlePouchModel = _.cloneDeep(acc.article);
		if (!currentArticle.input_quantity) {
			return false;
		}
		const response: ArticleChangeContextResponseModel = {
			currentArticle: currentArticle,
			order: <OrderStateModel>this.addArticleToOrder(currentArticle, acc.order)
		};
		return response;
	}
	// END - util legati a changeOrderArticle

	setOrderNewDraft<T extends ConfigurationViewModel>(
		order: OrderStateModel,
		organization: OrganizationPouchModel,
		configuration?: T
	) {
		if (organization) {
			order.header.organization = <OrganizationPouchModel>{
				business_name: organization.business_name,
				code_erp: organization.code_erp,
				code_item: organization.code_item
			};
			const currency = this.utilService.returnIsMainOfList<CurrencyPouchModel>(organization.currency);
			order.header.currency = {
				code_item: currency.code_item,
				description_short: currency.description_short
			};
			ConfigurationCustomerOrderHeaderSetting.setOrderHeaderDiscount(
				order,
				organization['unconditional_discount']
			);
			ConfigurationCustomerOrderHeaderSetting.setOrderHeaderDestinationDefault(
				order,
				organization.destination_list
			);

			// context application AGENT
			if (this.user.current_permission.context_application === ContextApplicationItemCodeEnum.AGENT) {
				order.header.agent = {
					code_item: this.user.current_permission.context_code.code,
					code_erp: this.user.current_permission.context_code.code_erp,
					description: this.user.current_permission.context_code.description
				};				
			}
			// Division
			const mainDivision = this.utilService.returnIsMainOfList<DivisionPouchModel>(organization.division_list);
			if (mainDivision && mainDivision.division) {
				order.header.division = mainDivision.division;
			}

			// Causal
			if (this.user.current_permission.context_application === ContextApplicationItemCodeEnum.B2C) {
				const order_causal =
					organization.organization_type === OrganizationTypeEnum.COMPANY
						? configuration.order_causal.find(i => i.code_item === ContextApplicationItemCodeEnum.B2B)
						: configuration.order_causal.find(i => i.code_item === ContextApplicationItemCodeEnum.B2C);
				order.header.order_causal = order_causal['causals_row'][0];
			} else {
				order.header.order_causal =
					mainDivision?.order_causal ||
					ConfigurationCustomerOrder.causalCode[this.user.current_permission.context_application];
			}
			order.header.payment_code =
				ConfigurationCustomerOrderOrganization.getOrganizationPreference(
					'payment_condition',
					this.auxiliaryTable.paymentList,
					organization
				);

			// method_shipping
			// TODO: deprecare order.header.shipping_mode
			order.header.shipping_method_code =
				ConfigurationCustomerOrderOrganization.getOrganizationPreference(
					'method_shipping',
					this.auxiliaryTable.methodShippingList,
					organization
				);

			ConfigurationCustomerOrderHeaderSetting.setOrderHeaderObjectForBodyTablePouchModel(
				order,
				this.orderHeaderObjectBodyTablePouchModelList
			);
			order.header.first_evasion_date =
				ConfigurationCustomerOrder.minDateSelectable[this.user.current_permission.context_application].valueOf();
		}
		order.source = 'SYSTEM';
	}

	/**
	 * Effettua il merge delle proprietà di article_recap*description
	 *
	 * @template T
	 * @param {T[]} articleList Eventuale modello di custom field che estende ArticlePouchModel
	 * @param {string[]} [propertyToBypass] Eventuale lista di proprietà da recuperare direttamente dall'article salvato nell'ordine per
	 * non perdere l'informazione nella modalità 'screenshot' (vedere frontend/src/app/constants/article.constant.ts).
	 * In mancanza del parametro il metodo mergia interamente l'elemento proveniente dal documento article_recap*description,
	 * sovrascrivendo qualsiasi riferimento precedentemente salvato nell'ordine.
	 * @returns {T[]}
	 * @memberof UtilOrderService
	 */
	mergeArticleRecapDescription<T extends ArticlePouchModel>(articleList: T[], propertyToBypass?: string[]): T[] {
		try {
			for (let i = 0; i < articleList.length; i++) {
				let articleRecapDescriptionItem: ArticleDescriptionItem = this.articleDescription.article_list.find(
					a => a.code_item === articleList[i].code_item
				);
				articleRecapDescriptionItem = articleRecapDescriptionItem
					? articleRecapDescriptionItem
					: <ArticleDescriptionItem>{};
				if (propertyToBypass) {
					for (let p = 0; p < propertyToBypass.length; p++) {
						articleRecapDescriptionItem[propertyToBypass[p]] =
							articleList[i].articleDescription[propertyToBypass[p]];
					}
				}
				articleList[i].articleDescription = articleRecapDescriptionItem;
			}
			return articleList;
		} catch (err) {
			console.log(err);
			throw new Error(err);
		}
	}

	/**
	 * Restituisce gli articoli filtrati per i code_item presenti in order.product_list, definendone sconti e prezzi in base
	 * all'organization o alla main division dell'articolo stesso. Da utilizzare per i soli ordini in bozza
	 *
	 * @param {OrderStateModel} order
	 * @param {ArticlePouchModel[]} articleList
	 * @param {OrganizationPouchModel} [organization]
	 * @returns {ArticlePouchModel[]}
	 * @memberof UtilOrderService
	 */
	returnFilteredAndMergedArticleListByOrderDraft(
		order: OrderStateModel,
		articleList: ArticlePouchModel[],
		organization?: OrganizationPouchModel
	): ArticlePouchModel[] {
		let articleListToProcess: ArticlePouchModel[];
		if (order && order.product_list && order.product_list.length) {
			const orderProductListCodeItem: string[] = order.product_list.map(i => i.code_item);
			articleListToProcess = _.cloneDeep(articleList.filter(i => orderProductListCodeItem.includes(i.code_item)));
			const mainDivision =
				organization && organization.division_list && organization.division_list.length > 0
					? this.utilService.returnIsMainOfList<DivisionPouchModel>(organization.division_list) ||
					  organization.division_list[0]
					: {};
			for (const article of articleListToProcess) {
				this.utilPriceService.mapArticlePrice(article, mainDivision.division || null);
			}
			articleListToProcess = this.mergeProductDetailInArticle(articleListToProcess, order);
			// Ordered quantity
			let articlesWarningMessage = '';
			articleListToProcess.forEach(article => {
				const availableQuantity =
					ConfigurationCustomerArticleAvailability.returnArticleAvailableQuantity(article, organization);
				if (availableQuantity !== null && article.ordered_quantity > availableQuantity) {
					// Article warning message
					const articleWarningMessage = this.sentencecasePipe.transform(
						this.utilTranslateService.translate.instant('article.quantity.update_from_to', {
							product: article.code_erp,
							from_qty: article.ordered_quantity,
							to_qty: availableQuantity
						})
					);
					articlesWarningMessage += `${articleWarningMessage}\n`;
					// Update quantity
					const orderProduct = order.product_list.find(p => p.code_item === article.code_item);
					orderProduct.ordered_quantity = availableQuantity;
					article.ordered_quantity = availableQuantity;
					article.input_quantity = availableQuantity;
					this.changeOrderArticle(
						{
							event: {
								target: {
									value: availableQuantity.toString()
								}
							},
							row: article,
							key: 'input_quantity'
						},
						order,
						organization,
						true
					);
				}
			});
			// Articles warning message
			if (articlesWarningMessage) {
				this.snackBar.open(articlesWarningMessage, 'OK', { duration: 5000 });
			}
		}
		return articleListToProcess;
	}

	/**
	 * Restituisce l'ordine con i soli articoli ancora presenti in article_recap**. Da utilizzare per i soli
	 * ordini in bozza
	 *
	 * @param {OrderStateModel} order
	 * @param {ArticlePouchModel[]} articleList
	 * @returns {OrderStateModel}
	 * @memberof UtilOrderService
	 */
	returnOrderDraftWithoutMissingArticle(order: OrderStateModel, articleList: ArticlePouchModel[]): OrderStateModel {
		const orderReturn: OrderStateModel = _.cloneDeep(order);
		if (order && order.product_list && order.product_list.length) {
			orderReturn.product_list = [];
			for (const orderArticle of order.product_list) {
				const articleListItem: ArticlePouchModel = articleList.find(
					i => i.code_item === orderArticle.code_item
				);
				if (articleListItem) {
					orderReturn.product_list.push(_.cloneDeep(orderArticle));
				}
			}
		}
		ConfigurationCustomerOrderHeaderSetting.updateOrderWeight(orderReturn);
		return orderReturn;
	}

	/**
	 * Restituisce una lista articoli generata da quelli presenti in order.product_list.
	 * Da utilizzare per gli ordini in stato diverso da bozza
	 *
	 * @param {OrderStateModel} order
	 * @returns {ArticlePouchModel[]}
	 * @memberof UtilOrderService
	 */
	returnArticleListByOrderSent(order: OrderStateModel): ArticlePouchModel[] {
		let articleList: ArticlePouchModel[] = order.product_list;
		for (const article of articleList) {
			this.utilPriceService.mapOrderArticlePrice(article);
		}
		articleList = this.mergeArticleRecapDescription<ArticlePouchModel>(articleList);
		return articleList;
	}

	/**
	 * Il metodo rimodella l'ordine nato da un utente anonimo per adeguarlo al salvataggio presso un db organization
	 *
	 * @param {OrderStateModel} orderFromLocalStorageRef
	 * @param {OrganizationPouchModel} organization
	 * @param {AuxiliaryTableStateModel} auxiliaryTable
	 * @param {ConfigurationViewModel} configuration
	 * @param {ArticlePouchModel[]} articleList
	 * @memberof UtilOrderService
	 */
	async saveOrderNewFromLocalStorageToOrganization(
		orderFromLocalStorageRef: OrderStateModel,
		organization: OrganizationPouchModel,
		configuration: ConfigurationViewModel,
		articleList: ArticlePouchModel[]
	): Promise<void> {
		try {
			// rimozione prop. `_rev` che determina conflitto nella crezione di un nuovo documento gemello su db terzo
			delete orderFromLocalStorageRef._rev;
			this.setOrderNewDraft<ConfigurationViewModel>(orderFromLocalStorageRef, organization, configuration);
			// rimozione di eventuali articoli non presenti sul listino dell'organization appena autenticata
			orderFromLocalStorageRef = this.returnOrderDraftWithoutMissingArticle(orderFromLocalStorageRef, articleList);
			// aggiornamento dei dati che potrebbero divergere tra il listino anonimo e quello dell'organization
			for (let i = 0; i < orderFromLocalStorageRef.product_list.length; i++) {
				orderFromLocalStorageRef.product_list[i] = await this.updateOrderArticleByOrganizationArticleList(
					orderFromLocalStorageRef.product_list[i],
					articleList,
					orderFromLocalStorageRef.header.division
				);
			}
			ConfigurationCustomerOrderHeaderSetting.updateOrderWeight(orderFromLocalStorageRef);
			// aggiornamento header price
			orderFromLocalStorageRef = await this.utilPriceService.updatePriceOrder(orderFromLocalStorageRef, organization);
			// rimozione della referenza all'ordine conservata nel localstorage
			this.localStorageService.clear('current_order_id');
			// salvataggio del nuovo ordine tramite flusso autenticato, che determina una nuova bozza nel db organization
			this.store.dispatch(OrderStateAction.save(new BaseState(orderFromLocalStorageRef)));
			return;
		} catch(err) {
			throw new Error(err);
		}
	}

	/**
	 * Restituisce un articolo formattato per il salvataggio ordine, aggiornato in base alle informazioni contentute
	 * nel listino passato comeparametro `article_list`
	 *
	 * @param {ArticlePouchModel} article
	 * @param {ArticlePouchModel[]} articleList
	 * @param {string} division
	 * @returns
	 * @memberof UtilOrderService
	 */
	updateOrderArticleByOrganizationArticleList(
		article: ArticlePouchModel,
		articleList: ArticlePouchModel[],
		division: string
	): Promise<ArticlePouchModel> {
		return new Promise(resolve => {
			try {
				// recupera l'articolo sul listino tramite `code_item`
				let articleUpdater: ArticlePouchModel;
				for (const articleListItem of articleList) {
					if (articleListItem.code_item === article.code_item) {
						articleUpdater = _.cloneDeep(articleListItem);
						break;
					}
				}
				// avviene il remap della proprietà articlePrice con i nuovi prezzi e sconti
				this.utilPriceService.mapArticlePrice(articleUpdater, division);
				// reimposta `input_quantity` dell'articolo di partenza
				// articleUpdater.input_quantity = article.input_quantity;
				resolve(this.returnArticleForOrderSave(articleUpdater));
			} catch(err) {
				throw new Error(err);
			}
		});
	}

	mergeArticleTester(article: ArticlePouchModel, articleList: ArticlePouchModel[]) {
		if (article.articleDescription.related_tester) {
			let articleTesterIndex: number;
			const articleTester: ArticlePouchModel = articleList.find((i, index) => {
				if (article.articleDescription.related_tester === i.code_item) {
					articleTesterIndex = index;
					return true;
				}
			});
			if (articleTester) {
				article.articleDescription.relatedArticleTester = articleTester;
				articleList.splice(articleTesterIndex, 1);
			}
		}
	}

	formatOrderCatalogArticleList(
		articleList: ArticlePouchModel[],
		division: string,
		order: OrderStateModel
	): ArticlePouchModel[] {
		for (const article of articleList) {
			this.utilPriceService.mapArticlePrice(article, division);
		}
		const articleListToReturn: ArticlePouchModel[] = _.cloneDeep(articleList);
		for (const article of articleList) {
			const articleToReturnIndex: number = this.utilService.getElementIndex(
				articleListToReturn,
				'code_item',
				article.code_item
			);
			if (articleToReturnIndex) {
				this.mergeArticleTester(articleListToReturn[articleToReturnIndex], articleListToReturn);
			}
		}
		return this.mergeProductDetailInArticle(articleListToReturn, order);
	}

	orderDraftCreate(organization: OrganizationPouchModel) {
		const order = new OrderStateModel();
		this.setOrderNewDraft(order, organization);
		this.store.dispatch(OrderStateAction.update(new BaseState(order)));
	}

	/**
	 * order delete
	 * @param order 
	 */
	dialogDeleteConfirm(order: OrderStateModel) {
		const dialog = this.dialog.open(DialogConfirmComponent, {
			data: {
				title: this.sentenceCasePipe.transform(this.translate.instant('order.action.delete')),
				text: this.sentenceCasePipe.transform(this.translate.instant('order.question.delete'))
			}
		});
		dialog.afterClosed().subscribe(res => {
			if (res) {
				if (order._id) {
					this.store.dispatch(OrderStateAction.remove(new BaseState(order)));
				}
			}
		});
	}

	/**
	 * order clone
	 */

	clone(order: OrderStateModel, key: OrderCloneTypeEnum = OrderCloneTypeEnum.HEADER_AND_ROWS) {
		order = _.cloneDeep(order);
		delete order._id;
		delete order._rev;
		delete order.header.submission_date;
		delete order.csuite;
		order.header.status = OrderStatusEnum.DRAFT;
		order.header.date = Date.now();
		if (key === OrderCloneTypeEnum.HEADER) {
			order.product_list = [];
			delete order.header.price;
		}
		this.updateArticle = true;
		this.store.dispatch(OrderStateAction.save(new BaseState(order)));
	}

	dialogCloneConfirm(order: OrderStateModel) {
		const dialogRef = this.dialog.open(DialogActionsComponent, {
			data: {
				title: `${this.sentenceCasePipe.transform(this.translate.instant('general.duplicate'))}
				${this.sentenceCasePipe.transform(this.translate.instant('order.name'))}`,
				text: this.sentenceCasePipe.transform(this.translate.instant('order.dialog.clone.text')),
				actions: ConfigurationCustomerOrder.headerAndRowsOrderClone[this.user.current_permission.context_application] ? [
					{
						key: OrderCloneTypeEnum.HEADER_AND_ROWS,
						text: 'order.dialog.clone.header_and_rows_option'
					}
				]:
				[
					{
						key: OrderCloneTypeEnum.HEADER,
						text: 'order.dialog.clone.header_option'
					},
					{
						key: OrderCloneTypeEnum.HEADER_AND_ROWS,
						text: 'order.dialog.clone.header_and_rows_option'
					}
				]
			},
			disableClose: true,
			panelClass: ['dialog-normal','michelangelo-theme-dialog']
		});
		dialogRef.afterClosed().subscribe(res => {
			if (res) {
				this.clone(order, res);
			}
		});
	}

	/**
	 * Aggiorna l'ordine con gli articoli forniti da `returnCalcSheetArticleList()`, mediante il metodo
	 * `changeOrderArticle()`
	 * @param file
	 * @param organization
	 * @param order
	 */
	calcSheetArticleListUpdateOrder(
		file: CalcSheetFile,
		organization: OrganizationPouchModel,
		order: OrderStateModel
	) : void {
		const calcSheetArticleList: CalcSheetItem[] = calcSheet.returnCalcSheetArticleList(file);
		let articleList: ArticlePouchModel[];
		this.utilStoreService.retrieveSyncState<ArticlePouchModel[]>(this.articleList$).subscribe(eArticleList => {
			articleList = eArticleList.data;
		});
		const articleAddedList: string[] = [];
		const articleNotAddedList: string[] = [];
		const division: string =
			this.utilService.returnIsMainOfList<DivisionPouchModel>(organization.division_list).division;
		for (const item of calcSheetArticleList) {
			// verifica esistenza articolo
			const article: ArticlePouchModel = articleList.find(i => i.code_erp === item.code);
			if (article && item.quantity) {
				// verifica disponibilità articolo
				const availableQuantity =
					ConfigurationCustomerArticleAvailability.returnArticleAvailableQuantity(article, organization);
				if (availableQuantity !== null && item.quantity > availableQuantity) {
					item.quantity = availableQuantity;
				}
				// la verifica della disponibilità potrebbe restituire 0, rendendo inutile l'aggiunta dell'articlo
				if (item.quantity > 0) {
					this.utilPriceService.mapArticlePrice(article, division);
					this.changeOrderArticle(
						{
							event: {
								target: {
									value: item.quantity.toString()
								}
							},
							key: 'input_quantity',
							row: article
						},
						order,
						organization,
						false
					);
					articleAddedList.push(item.code);
				} else {
					articleNotAddedList.push(item.code);
				}
			} else {
				articleNotAddedList.push(item.code);
			}
		}
		let message: string;
		message = this.sentencecasePipe.transform(`${this.translate.instant('article.added_to_order_or_modified')}:`);
		if (articleAddedList.length > 0) {
			message = util.returnMessageCodeUl(message, articleAddedList);
		} else {
			message = message + ' 0<br><br>'
		}
		// gestione errore per gli articoli non trovati o non disponibili
		if (articleNotAddedList.length > 0) {
			message = message + this.sentencecasePipe.transform(`${this.translate.instant('article.error.not_found_plural')}:`);
			message = util.returnMessageCodeUl(message, articleNotAddedList);
		}
		this.snackBar.openFromComponent(MatSnackBarWrapperComponent, {
			duration: null,
			data: {
				message: message,
				action:'OK'
			}
		});
	}

	getFormattedAddress(destination: AddressPouchModel) {
		const address = destination;
		let formattedAddress = '';
		if (address) {
			formattedAddress =
				(address.locality ? address.locality + ', ' : '-') +
				(address.zip_code ? address.zip_code + ' - ' : '') +
				(address.address ? this.titlecasePipe.transform(address.address) : '-');
		}
		return formattedAddress;
	}



	forwardOrder(order, organization) {

			// order send
			this.orderChangeTemp =
				this.orderChangeTemp ? this.orderChangeTemp : order;
			if (
				this.orderChangeTemp &&
				this.orderChangeTemp.product_list &&
				this.orderChangeTemp.product_list.length > 0
			) {
				// if (
				// 	this.utilOrderService.orderChangeTemp.header.discrepancy_list &&
				// 	this.utilOrderService.orderChangeTemp.header.discrepancy_list.length
				// ) {
				// 	this.utilOrderService.orderChangeTemp.header.status = OrderStatusEnum.TO_AUTHORIZE;
				// } else {
				this.orderChangeTemp.header.status = ConfigurationCustomerOrder.orderToSendStatus;
				// }
				this.orderChangeTemp.header.submission_date = new Date().getTime();
				if (this.utilService.returnIsMainOfList<DivisionPouchModel>(organization.division_list)) {
					this.orderChangeTemp.header.bank_object = {
						description:
							this.utilService.returnIsMainOfList<DivisionPouchModel>(organization.division_list)
								.banks_descriptions &&
							this.utilService.returnIsMainOfList<DivisionPouchModel>(organization.division_list)
								.banks_descriptions[0]
								? this.utilService.returnIsMainOfList<DivisionPouchModel>(organization.division_list)
									.banks_descriptions[0]
								: '',
						iban: this.utilService.returnIsMainOfList<DivisionPouchModel>(organization.division_list).iban
					};
				}
				// TODO: consolidare controllo su data antecedente rispetto alla regola
				const isBeforeToday: boolean =
					moment(this.orderChangeTemp.header.first_evasion_date)
						.diff(
							ConfigurationCustomerOrder.minDateSelectable[this.user.current_permission.context_application],
							'd',
							false
						) < 0;
				if (isBeforeToday) {
					this.orderChangeTemp.header.first_evasion_date =
						moment(ConfigurationCustomerOrder.minDateSelectable[this.user.current_permission.context_application])
							.valueOf();
				}
				this.utilService.deleteEmptyProperties(this.orderChangeTemp.header.bank_object);
				this.updateArticle = true;
				this.updateOrderSource.next({
					order: this.orderChangeTemp,
					useLoader: true,
					statusChange: true,
					debounceTime: 0
				});
			}

	}




}

