<mat-toolbar *ngIf="localListHandlerTextFilterShow">
	<mat-toolbar-row class="search-wrapper">
		<span class="flex-span"></span>
		<td-search-box
			*ngIf="localListHandlerData && localListHandlerData.filters && localListHandlerData.filters.localSearchText"
			backIcon="search"
			[placeholder]="'general.search' | translate | sentencecase"
			[(ngModel)]="localListHandlerData.filters.localSearchText.value"
			[showUnderline]="true"
			[debounce]="500"
			[alwaysVisible]="true"
			(searchDebounce)="localListHandlerApplyFilter()"
		>
		</td-search-box>
	</mat-toolbar-row>
</mat-toolbar>
<ng-container
	*ngIf="
		localListHandlerData && localListHandlerData.dataSubset && localListHandlerData.dataSubset.length > 0;
		else noData
	"
>
	<mat-checkbox
		*ngIf="isBackoffice"
		color="primary"
		class="mat-checkbox-style select-all"
		[checked]="returnAllNewsChecked(localListHandlerData.dataSubset)"
		[indeterminate]="returnSomeNewsChecked(localListHandlerData.dataSubset)"
		(change)="setNewsCheckStatus($event, localListHandlerData.dataSubset)"
	>
		<strong>
			{{
				(
					returnAllNewsChecked(localListHandlerData.dataSubset) === false ?
					'general.check_all' :
					'general.uncheck_all'
				) |
				translate |
				sentencecase
			}}
		</strong>
	</mat-checkbox>
	<ng-container *ngFor="let data of localListHandlerData.dataSubset">
		<mat-accordion [ngClass]="{ 'archived-news': data.header.status === newsStatusEnum.ARCHIVE }">
			<mat-expansion-panel hideToggle>
				<mat-expansion-panel-header class="autoheight">
					<mat-panel-title>
						<mat-checkbox
							color="primary"
							*ngIf="isBackoffice"
							class="checkbox"
							[checked]="isNewsSelected(data)"
							(click)="$event.stopPropagation()"
							(change)="selectedData($event, data)"
						></mat-checkbox>
						<span class="subject-and-pubdate-wrapper">
							<span class="subject">{{ data.body.subject }}</span>
							<span class="pubdate">{{ data.date_update | dateAsAgo }}</span>
						</span>
					</mat-panel-title>
					<!-- <mat-panel-description>
						{{ data.date_update | dateAsAgo }}
					</mat-panel-description> -->
					<div
						*ngIf="isBackoffice"
						class="action-buttons"
					>
						<mat-icon
							[matTooltip]="
								(
									data.header.published &&
									data.header.date_publication &&
									data.header.date_publication < timestampNow ? 'news.public' : 'news.not_public'
								) |
								translate |
								sentencecase
							"
						>
							{{
								data.header.published &&
								data.header.date_publication &&
								data.header.date_publication < timestampNow ? 'public' : 'public_off'
							}}
						</mat-icon>
						<button
							mat-icon-button
							(click)="editNewsDetail(data)"
						>
							<mat-icon
								class="pencil"
								[matTooltip]="'news.action.edit' | translate | sentencecase"
								[svgIcon]="'custom-pencil'"
							>
								edit
							</mat-icon>
						</button>
						<button mat-icon-button (click)="newsActionHandler($event, newsActionEnum.TOGGLE_STATUS, data)">
							<mat-icon
								*ngIf="data.header.status === newsStatusEnum.NEW"
								class="inbox"
								[svgIcon]="'custom-inbox'"
								[matTooltip]="'news.action.mark_as_archived' | translate | sentencecase"
							>
								inbox
							</mat-icon>
							<mat-icon
								*ngIf="data.header.status === newsStatusEnum.ARCHIVE"
								class="inbox"
								[svgIcon]="'custom-insert_drive_file'"
								[matTooltip]="'news.action.mark_as_new' | translate | sentencecase"
							>
								insert_drive_file
							</mat-icon>
						</button>
						<button
							mat-icon-button
							(click)="newsActionHandler($event, newsActionEnum.DELETE, data)"
						>
							<mat-icon
								class="trash"
								[svgIcon]="'custom-trash'"
								[matTooltip]="'news.action.delete' | translate | sentencecase"
							>
								delete
							</mat-icon>
						</button>
					</div>
				</mat-expansion-panel-header>
				<div
					class="content"
					[ngClass]="{ 'is-backoffice': isBackoffice }"
				>
					<div [innerHTML]="data.body.message"></div>
					<div
						*ngFor="let attach of data.body.attachment_list"
						class="attachments"
					>
						<a
							[attr.href]="
								appConfig.envConfig.bucketManager.be_url +
								'/news/download-multipart?path=/' +
								data.code_item +
								'/' +
								attach.name +
								'&token=' +
								appConfig.token
							"
							target="_blank"
						>
							<mat-icon [svgIcon]="'custom-attach'">attach_file</mat-icon>
							{{ attach.name }}
						</a>
					</div>
				</div>
			</mat-expansion-panel>
		</mat-accordion>
		<!-- <mat-divider class="bottom-spacing"></mat-divider> -->
	</ng-container>
	<pagination-and-actions>
		<ng-container pagination>
			<mat-paginator
				*ngIf="localListHandlerData.pagination && localListHandlerPaginatorShow"
				#matPaginator
				(page)="localListHandlerApplyFilter({ pagination: $event })"
				[length]="localListHandlerData.pagination.totalFilteredItemCount"
				[pageSize]="localListHandlerData.pagination.pageSize"
				[pageSizeOptions]="[5, 10, 25, 100]"
				[hidePageSize]="true"
			>
			</mat-paginator>
		</ng-container>
		<ng-container actions>
			<ng-container *ngIf="isBackoffice && _selectedNewsList.length">
				<button
					mat-stroked-button
					*ngIf="statusFilter === ROUTE_URL.new"
					[ngClass]="{ 'with-icon no-label': !utilService.isDesktop() }"
					type="button"
					(click)="newsActionHandler($event, newsActionEnum.ARCHIVE)"
				>
					<mat-icon
						[svgIcon]="'custom-envelop'"
						*ngIf="!utilService.isDesktop()"
					>
						mail
					</mat-icon>
					<span *ngIf="utilService.isDesktop()">
						{{ 'news.action.mark_as_archived' | translate | sentencecase }}
					</span>
				</button>
				<button
					mat-stroked-button
					[ngClass]="{ 'with-icon no-label': !utilService.isDesktop() }"
					type="button"
					(click)="newsActionHandler($event, newsActionEnum.DELETE)"
				>
					<mat-icon
						[svgIcon]="'custom-trash'"
						*ngIf="!utilService.isDesktop()"
					>
						delete
					</mat-icon>
					<span
						class="label"
						*ngIf="utilService.isDesktop()"
					>
						{{ 'news.action.delete_selected' | translate | sentencecase }}
					</span>
				</button>
			</ng-container>
			<button
				mat-fab
				*ngIf="localListHandlerWidgetWrapperCanCreate"
				(click)="createNewEvent.emit()"
				[matTooltip]="'news.action.create' | translate | sentencecase"
				matTooltipPosition="left"
				class="mat-fab-button__in-page"
				color="primary"
			>
				<mat-icon>add</mat-icon>
				<span class="label">{{ 'news.action.create' | translate | sentencecase }}</span>
			</button>
		</ng-container>
	</pagination-and-actions>
<!--	<mat-toolbar class="____mt-20">-->
<!--		<mat-toolbar-row>-->
<!--			<ng-container *ngIf="isBackoffice && _selectedNewsList.length">-->
<!--				<button-->
<!--					mat-stroked-button-->
<!--					*ngIf="statusFilter === ROUTE_URL.new"-->
<!--					[ngClass]="{ 'with-icon': utilService.isMobile() }"-->
<!--					type="button"-->
<!--					(click)="newsActionHandler($event, newsActionEnum.ARCHIVE)"-->
<!--				>-->
<!--					<mat-icon [svgIcon]="'custom-envelop'" *ngIf="utilService.isMobile()">mail</mat-icon>-->
<!--					<span *ngIf="!utilService.isMobile()">-->
<!--						{{ 'news.action.mark_as_archived' | translate | sentencecase }}-->
<!--					</span>-->
<!--				</button>-->
<!--				<button-->
<!--					mat-stroked-button-->
<!--					[ngClass]="{ 'with-icon': utilService.isMobile() }"-->
<!--					type="button"-->
<!--					(click)="newsActionHandler($event, newsActionEnum.DELETE)"-->
<!--				>-->
<!--					<mat-icon [svgIcon]="'custom-trash'" *ngIf="utilService.isMobile()">delete</mat-icon>-->
<!--					<span *ngIf="!utilService.isMobile()">-->
<!--						{{ 'news.action.delete_selected' | translate | sentencecase }}-->
<!--					</span>-->
<!--				</button>-->
<!--			</ng-container>-->
<!--			<span class="flex-span"></span>-->
<!--			<mat-paginator-->
<!--				*ngIf="localListHandlerData.pagination && localListHandlerPaginatorShow"-->
<!--				#matPaginator-->
<!--				(page)="localListHandlerApplyFilter({ pagination: $event })"-->
<!--				[length]="localListHandlerData.pagination.totalFilteredItemCount"-->
<!--				[pageSize]="localListHandlerData.pagination.pageSize"-->
<!--				[pageSizeOptions]="[5, 10, 25, 100]"-->
<!--				[hidePageSize]="true"-->
<!--			>-->
<!--			</mat-paginator>-->
<!--		</mat-toolbar-row>-->
<!--	</mat-toolbar>-->
</ng-container>
<!-- <loading-results-spinner *ngIf="isLoading"></loading-results-spinner> -->
<ng-template #noData>
	<p class="placeholder" *ngIf="!isLoading">
		{{ 'general.no_results' | translate | sentencecase }}
	</p>
</ng-template>
