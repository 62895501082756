import { AgentCustomerLockStatus } from './agent-customer-pouch.model';
import { BasePouchModel, BodyTablePouchModel, HeaderRecordPouchModel } from './base-pouch.model';
import { ContactPouchModel } from './contact-pouch.model';
import { DestinationPouchModel } from './destination-pouch.model';
import { CurrencyPouchModel } from './currency-pouch.model';
import {
	SignEnum,
	GiveTakeFlagEnum,
	ExpiredFlagEnum,
	SourceCreditGrantedEnum,
	ListPouchModel
} from './util-pouch.model';

export enum OrganizationTypeEnum {
	COMPANY = 'COMPANY',
	PRIVATE = 'PRIVATE'
}

export enum OrganizationLevelEnum {
	LEAD = 'lead',
	PROSPECT = 'prospect',
	CUSTOMER = 'customer'
}

export interface OrganizationPouchModel extends BasePouchModel, BodyTablePouchModel {
	article_recap_code_item?: boolean;
	business_name?: string;
	code_price_list?: string;
	contact_list?: ContactPouchModel[];
	crm?: {
		revenue_year?: number;
		organization_sector?: string;
		employees_number?: number;
		organization_international_rating?: string;
		organization_rating?: number;
		url_website?: string;
		url_linkedin?: string;
		url_documents?: string;
		url_recipes?: string;
		url_sales?: string;
		url_tickets?: string;
	};
	description?: string;
	destination_list?: DestinationPouchModel[];
	division_list?: DivisionPouchModel[];
	level?: OrganizationLevelEnum;
	organization_class?: string;
	organization_type?: OrganizationTypeEnum;
	status?: string;
	tax_data?: {
		tax_code?: string;
		vat_number?: string;
		sdi_code?: string;
		pec?: string;
	};
	currency?: CurrencyPouchModel[]
}

export interface DivisionPouchModel extends ListPouchModel {
	area_commercial?: string;
	bank_account?: string;
	banks_descriptions?: string[];
	carrier?: string;
	code_agent?: string[];
	code_area_manager?: string;
	code_bank_abi_cab?: string;
	code_lock?: string;
	code_national_abi_cab?: string;
	conversion?: boolean;
	credit?: string;
	delivery_days?: string;
	default_customer_line?: string;
	discounts?: number[];
	division?: string;
	iban?: string;
	method_delivery?: string;
	method_shipping?: string;
	order_causal?: string;
	payment_condition?: string;
	place_departure_goods_default?: string;
	price?: number;
	separate_orders_line?: string;
	status?: AgentCustomerLockStatus;
	valid?: boolean;
	vat?: number;
}

export interface OrganizationCreditPouchModel extends HeaderRecordPouchModel {
	reliability?: string;
	source_credit_granted: SourceCreditGrantedEnum;

	date_credit_granted?: string;
	date_last_payment?: string;
	date_last_invoice?: string;
	date_last_deadline?: string;
	days_since_last_deadline?: number;
	related_customers?: string[];
	code_customer_new?: string;
	line_customer?: string;

	sign_credit_granted?: SignEnum;
	amount_credit_granted?: number;
	sign_balance_b_s?: SignEnum;
	amount_balance_b_s?: number;
	sign_expired?: SignEnum;
	amount_expired?: number;
	sign_actual_risk?: SignEnum;
	amount_actual_risk?: number;
	sign_total_risk?: SignEnum;
	amount_total_risk?: number;
	sign_total_expired?: SignEnum;
	amount_total_expired?: number;
	sign_annual_turnover?: SignEnum;
	amount_annual_turnover?: number;
	sign_residual_sales_order?: SignEnum;
	amount_residual_sales_order?: number;
	sign_out_credit?: SignEnum;
	amount_out_credit?: number;
	sign_outstandings?: SignEnum;
	amount_outstandings?: number;
	number_outstandings?: number;
	sign_exchange_exposure?: SignEnum;
	amount_exchange_exposure?: number;
	sign_positive_shipping_in_progress?: SignEnum;
	amount_positive_shipping_in_progress?: number;
	sign_negative_shipping_in_progress?: SignEnum;
	amount_negative_shipping_in_progress?: number;
	sign_secured_credit?: SignEnum;
	amount_secured_credit?: number;
	sign_debits_not_recognized?: SignEnum;
	amount_debits_not_recognized?: number;
	sign_credits_not_recognized?: SignEnum;
	amount_credits_not_recognized?: number;
	sign_payment_forecasts?: SignEnum;
	amount_payment_forecasts?: number;
	sign_xab_not_invoiced?: SignEnum;
	amount_xab_not_invoiced?: number;
	sign_returns_not_invoiced?: SignEnum;
	amount_returns_not_invoiced?: number;
	sign_commitments_sales_order?: SignEnum;
	amount_commitments_sales_order?: number;

	batch_list: OrganizationCreditBatchPouchModel[];
	effect_list: OrganizationCreditEffectPouchModel[];
	divisions: OrganizationCreditDivisionPouchModel;
}

export interface OrganizationCreditDivisionPouchModel {
	reliability?: string;
	division: string;
	valid: boolean;

	date_credit_granted?: string;
	date_last_payment?: string;
	date_last_invoice?: string;
	date_last_deadline?: string;
	days_since_last_deadline?: number;

	sign_balance_b_s?: SignEnum;
	amount_balance_b_s?: number;
	sign_expired?: SignEnum;
	amount_expired?: number;
	sign_actual_risk?: SignEnum;
	amount_actual_risk?: number;
	sign_total_risk?: SignEnum;
	amount_total_risk?: number;
	sign_total_expired?: SignEnum;
	amount_total_expired?: number;
	sign_annual_turnover?: SignEnum;
	amount_annual_turnover?: number;
	sign_residual_sales_order?: SignEnum;
	amount_residual_sales_order?: number;
	sign_out_credit?: SignEnum;
	amount_out_credit?: number;
	sign_outstandings?: SignEnum;
	amount_outstandings?: number;
	number_outstandings?: number;
	sign_exchange_exposure?: SignEnum;
	amount_exchange_exposure?: number;
	sign_positive_shipping_in_progress?: SignEnum;
	amount_positive_shipping_in_progress?: number;
	sign_negative_shipping_in_progress?: SignEnum;
	amount_negative_shipping_in_progress?: number;
	sign_secured_credit?: SignEnum;
	amount_secured_credit?: number;
	sign_debits_not_recognized?: SignEnum;
	amount_debits_not_recognized?: number;
	sign_credits_not_recognized?: SignEnum;
	amount_credits_not_recognized?: number;
	sign_payment_forecasts?: SignEnum;
	amount_payment_forecasts?: number;
	sign_xab_not_invoiced?: SignEnum;
	amount_xab_not_invoiced?: number;
	sign_returns_not_invoiced?: SignEnum;
	amount_returns_not_invoiced?: number;
	sign_commitments_sales_order?: SignEnum;
	amount_commitments_sales_order?: number;

	batch_list?: OrganizationCreditBatchPouchModel[];
	effect_list?: OrganizationCreditEffectPouchModel[];
}

export interface OrganizationCreditBatchPouchModel {
	number_document: string;
	date_document: string;
	date_document_ms: number;
	amount_document: number;
	flag_give_take: GiveTakeFlagEnum;
	number_batch: number;
	description_document: string;
	date_expiration: string;
	date_expiration_ms: number;
	flag_expired: ExpiredFlagEnum;
	flag_expired_bool: boolean; // Calcolato da Marco a partire da flag_expired
}

export interface OrganizationCreditEffectPouchModel {
	type_effect: string; // Mappare in enum
	number_effect: number;
	amount_effect: number;
	date_expiration_effect: string;
	sign_effect: string; // Mappare in enum
	invoice_list: {
		number_document: string;
		date_document: string;
	}[];
}
