import { Component, OnDestroy, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import {
	BaseStateModel,
	SideBarPositionValues,
	SubscribeManagerService
} from '@saep-ict/angular-core';
import { LocalListHandlerBaseModel, ArticlePouchModel, ArticleDescriptionItem } from '@saep-ict/pouch_agent_models';
import { filter, map } from 'rxjs/operators';
import { StateFeature } from '../../../state';
import { Observable } from 'rxjs';
import * as ConfigurationCustomerArticle from '../../../constants/configuration-customer/article/article.constant';
import { UtilBreadcrumbService } from '../../../service/util/util-breadcrumb.service';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ArticleStockActionEnum, ArticleStockStateAction } from '../../../state/article-stock/article-stock.actions';
import { AngularSpin8CoreUtilArticleService, ArticleRecapStockStateModel } from '@saep-ict/angular-spin8-core';
import * as ConfigurationCustomerAppStructure from '../../../constants/configuration-customer/app-structure/app-structure.constant';
import _ from 'lodash';
import { ArticleStockStructureWrapperPlainListComponent } from '../../../widget/article/article-stock-structure-wrapper-plain-list/article-stock-structure-wrapper-plain-list.component';

@Component({
	selector: 'product-stock',
	templateUrl: './product-stock.component.html',
	styleUrls: ['./product-stock.component.scss'],
	providers: [SubscribeManagerService]
})
export class ProductStockComponent implements OnDestroy {
	@ViewChild(ArticleStockStructureWrapperPlainListComponent)
	articleStockStructureWrapperPlainListComponent: ArticleStockStructureWrapperPlainListComponent;

	articleStock$: Observable<BaseStateModel<ArticleRecapStockStateModel>> = this.store.select(
		StateFeature.getArticleStockState
	);
	articleStock: ArticleDescriptionItem[];

	// articleList$: Observable<BaseStateModel<ArticlePouchModel[]>> = this.store.select(StateFeature.getArticleList);
	// articleList: ArticlePouchModel[];

	filteredArticleMap: {
		all: ArticlePouchModel[];
		freeSample: ArticlePouchModel[];
		regular: ArticlePouchModel[];
	};

	public listPageBaseData = <LocalListHandlerBaseModel<any>>{
		data: [],
		filters: {
			localSearchText: {
				value: null,
				key_list: [
					'description.article',
					ConfigurationCustomerAppStructure.erp_has_erp ? 'code_erp' : 'code_item'
				]
			}
		},
		columnList: ConfigurationCustomerArticle.articleColumnMapBackofficeProductStockColumnList,
		sort: {
			name: ConfigurationCustomerAppStructure.erp_has_erp ? 'code_erp' : 'code_item',
			order: 'ASC'
		},
		sortRemapObject: {
			description: 'articleDescription.language_list.description'
		}
	};

    sideBarPositionValues = SideBarPositionValues;

	constructor(
		private subscribeManagerService: SubscribeManagerService,
		private store: Store,
		private utilBreadcrumbService: UtilBreadcrumbService,
		private articleService: AngularSpin8CoreUtilArticleService
	) {
		this.store.dispatch(ArticleStockStateAction.load());
		this.subscribeManagerService.populate(this.subscribeProductStock().subscribe(), 'subscribeProductStock');
		this.utilBreadcrumbService.title.value = this.utilBreadcrumbService.getBreadcrumbTitle('product_stock');
		this.utilBreadcrumbService.updateActiveNavigationItemSource.next(['product_stock']);
	}

	ngOnDestroy(): void {
		this.subscribeManagerService.destroy();
		this.store.dispatch(ArticleStockStateAction.reset());
		this.utilBreadcrumbService.unsetRouteMetaInformation();
	}

	subscribeProductStock() {
		return this.articleStock$.pipe(
			filter((state: BaseStateModel<ArticleRecapStockStateModel>) => !!(state && state.data)),
			map((state: BaseStateModel<ArticleRecapStockStateModel>) => {
				switch (state.type) {
					case ArticleStockActionEnum.UPDATE:
						this.articleStock = state.data.article_list;
						this.articleStock = this.applyDefaultFilter(this.articleStock);
						this.getFilteredArticleList();
						this.articleStockStructureWrapperPlainListComponent.updateListPageBaseData(this.articleStock);
						break;
					case ArticleStockActionEnum.ERROR:
						throw new Error(ArticleStockActionEnum.ERROR);
				}
			})
		);
	}

	applyDefaultFilter(articleList: ArticleDescriptionItem[]): ArticleDescriptionItem[] {
		return articleList.filter(article => article.valid);
	}

	getFilteredArticleList() {
		this.filteredArticleMap = {
			all: this.articleStock,
			freeSample: this.articleStock.filter(item => item.free_sample === 'S'),
			regular: this.articleStock.filter(item => item.free_sample !== 'S')
		};
	}

	// WIP tab-wrapper
	filterOnTabClick(event: MatTabChangeEvent) {
		let filteredArticleList: ArticlePouchModel[];
		switch (event.tab.textLabel) {
			case 'all_products':
				filteredArticleList = this.filteredArticleMap.all;
				break;
			case 'regular':
				filteredArticleList = this.filteredArticleMap.regular;
				break;
			case 'posm_gwp':
				filteredArticleList = this.filteredArticleMap.freeSample;
				break;
		}
		this.articleStockStructureWrapperPlainListComponent.updateListPageBaseData(filteredArticleList);
	}
}
