import { Component, OnDestroy, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseStateModel, FormDynamicConfigurationModel, SideBarPositionValues, SubscribeManagerService } from '@saep-ict/angular-core';
import {
	LocalListHandlerBaseModel,
	OrganizationLevelEnum,
	OrganizationPouchModel,
	OrganizationTypeEnum
} from '@saep-ict/pouch_agent_models';
import { OrganizationListFilterCreationFieldMapFormValue } from '../../../model/organization.model';
import * as ConfigurationOrganization from '../../../constants/organization.constants';
import * as ConfigurationCustomerAppStructure from '../../../constants/configuration-customer/app-structure/app-structure.constant';
import * as ConfigurationCustomerOrganization from '../../../constants/configuration-customer/organization/organization.constant';
import { Store } from '@ngrx/store';
import { StateFeature } from '../../../state';
import {
	OrganizationListActionEnum,
	OrganizationListStateAction
} from '../../../state/common/organization-list/organization-list.actions';
import { TdPagingBarComponent } from '@covalent/core/paging';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { filter, map, mergeMap, take } from 'rxjs/operators';
import { DialogContextCodeEditComponent } from '../../../widget/dialog/dialog-context-code-edit/dialog-context-code-edit.component';
import { ITdDataTableColumn } from '@covalent/core/data-table';
import { OrganizationListWrapperComponent } from '../../../widget/organization/organization-list-wrapper/organization-list-wrapper.component';
import {
	ContextApplicationItemCodeEnum,
	UserDetailModel,
	contextCodeItemCompany,
	ROUTE_URL,
	AngularSpin8CoreUtilCompanyService,
	OrganizationStateModel
} from '@saep-ict/angular-spin8-core';
import { UtilBreadcrumbService } from '../../../service/util/util-breadcrumb.service';
import { UtilOrderService } from '../../../service/util/util-order.service';
import { ExportExcelService } from '../../../service/export/export-excel.service';
import * as ConfigurationCustomerCalcSheet from '../../../constants/configuration-customer/calc-sheet/calc-sheet.constant';

@Component({
	selector: 'organization',
	templateUrl: './organization.component.html',
	styleUrls: ['./organization.component.scss'],
	providers: [SubscribeManagerService]
})
export class OrganizationComponent implements OnDestroy {
	@ViewChild('pagingBarPageSize') public pagingBarPageSize: TdPagingBarComponent;
	@ViewChild(OrganizationListWrapperComponent)
	organizationListWrapperComponent: OrganizationListWrapperComponent;

	organizationList$: Observable<BaseStateModel<OrganizationStateModel[]>> = this.store.select(
		StateFeature.getOrganizationListState
	);
	organizationList: OrganizationStateModel[];

	user$: Observable<BaseStateModel<UserDetailModel>> = this.store.select(StateFeature.getUserState);
	user: UserDetailModel;

	contextApplicationItemCodeEnum = ContextApplicationItemCodeEnum;

	listPageBaseData = <LocalListHandlerBaseModel<OrganizationStateModel>>{
		pagination: {
			pageSize: this.contextApplicationItemCodeEnum.CRM ? 25 : 10
		},
		filters: {
			localSearchText: {
				value: null,
				key_list: [
					ConfigurationCustomerAppStructure.erp_has_erp ? 'code_erp' : 'code_item',
					'business_name',
					'tax_data.vat_number',
					'tax_data.tax_code',
					'destination_list.address.locality',
					'destination_list.address.address'
				]
			}
		},
		sort: {
			name: 'business_name',
			order: 'ASC'
		},
		data: []
	};

	formFilters: FormGroup;
	columns: ITdDataTableColumn[];

	// TODO - Creare un enum?
	companyStates = ['ENABLED', 'DISABLED'];

	organizationLevel: OrganizationLevelEnum;
	organizationTypeList = Object.values(OrganizationTypeEnum);
	sideBarPositionValues = SideBarPositionValues;
	configurationCustomerOrganization = ConfigurationCustomerOrganization;
	configurationCustomerCalcSheet = ConfigurationCustomerCalcSheet;
	currentContext: ContextApplicationItemCodeEnum;

	formDynamicConfiguration: FormDynamicConfigurationModel;
	organizationListFiltered: OrganizationPouchModel[] = [];
	constructor(
		private store: Store,
		private router: Router,
		private dialog: MatDialog,
		private subscribeManagerService: SubscribeManagerService,
		public utilCompanyService: AngularSpin8CoreUtilCompanyService,
		private activatedRoute: ActivatedRoute,
		private fb: FormBuilder,
		private utilBreadcrumbService: UtilBreadcrumbService,
		private utilOrderService: UtilOrderService,
		private exportService: ExportExcelService
	) {
		this.store.dispatch(OrganizationListStateAction.reset());
		this.store.dispatch(OrganizationListStateAction.loadAll());

		// User
		this.user$.pipe(take(1)).subscribe(res => {
			if (res) {
				this.user = res.data;
				this.currentContext = res ? res.data.current_permission.context_application : null;
			}
		});

		this.columns = ConfigurationCustomerOrganization.columnList(this.currentContext);
		this.subscribeManagerService.populate(
			this.subscribeOrganizationList().subscribe(),
			'subscribeOrganizationList'
		);
	}

	ngOnDestroy() {
		// non resettare. Serve per recuperare il dettaglio organization dalla lista di organization
		// this.store.dispatch(OrganizationListStateAction.reset());
		this.subscribeManagerService.destroy();
		this.utilBreadcrumbService.unsetRouteMetaInformation();
	}

	/**
	 * Subscription
	 */

	subscribeOrganizationList() {
		return this.activatedRoute.paramMap.pipe(
			mergeMap(params => {
				switch (this.user.current_permission.context_application) {
					case ContextApplicationItemCodeEnum.AGENT: {
						this.organizationLevel = OrganizationLevelEnum.CUSTOMER;
						break;
					}
					default: {
						let organizationLevel: string = params.get('organizationLevel');
						organizationLevel = organizationLevel ? organizationLevel.toUpperCase() : null;
						this.organizationLevel = OrganizationLevelEnum[organizationLevel];
					}
				}
				this.setSpecificVisibleTableColumn();
				this.setRouteMetaInformation();
				return this.organizationList$;
			}),
			filter((state: BaseStateModel<OrganizationPouchModel[]>) => !!(state && state.data)),
			map(async (state: BaseStateModel<OrganizationPouchModel[]>) => {
				switch (state.type) {
					case OrganizationListActionEnum.UPDATE:
						this.applyPermanentFilters(state.data);
						break;

					case OrganizationListActionEnum.ERROR:
						throw new Error(OrganizationListActionEnum.ERROR);
						break;

					default:
						break;
				}
				this.formDynamicConfiguration = {
					creationFieldMap: await ConfigurationCustomerOrganization.listFilterCreationFieldMap(
						this.user.current_permission.context_application,
						{
							status: this.companyStates
						},
					),
					emitAlsoNonValidForm: false,
					buttonReset: {
						show: true
					}
				};
				this.formDynamicConfiguration = _.cloneDeep(this.formDynamicConfiguration);
				return state;
			})
		);
	}

	applyPermanentFilters(organizationList: OrganizationStateModel[]) {
		this.organizationList = ConfigurationOrganization.listFilter(
			organizationList,
			this.user.current_permission.context_application,
			this.organizationLevel
		);
		this.organizationListWrapperComponent.updateListPageBaseData(this.organizationList);
	}

	/**
	 * navigation
	 */

	orderDraftCreate(e: OrganizationPouchModel) {
		console.log(e);
		this.utilOrderService.orderDraftCreate(e);
		this.router.navigate([ROUTE_URL.private, ROUTE_URL.orders, 'DRAFT', e.code_item, 'new', 'header-edit']);
	}

	goToDetail(level: string, code: string) {
		if (level === OrganizationLevelEnum.CUSTOMER) {
			this.router.navigate([ROUTE_URL.private, ROUTE_URL.organizations, level, code]);
		} else {
			this.router.navigate([ROUTE_URL.private, ROUTE_URL.organizations, level, code, ROUTE_URL.details]);
		}
	}

	createNew() {
		const dialogRef: MatDialogRef<DialogContextCodeEditComponent> = this.dialog.open(
			DialogContextCodeEditComponent,
			{
				data: { ...contextCodeItemCompany, contestoDialog: 'organization', level: this.organizationLevel },
				panelClass: ['dialog-medium', 'michelangelo-theme-dialog']
			}
		);
		dialogRef.afterClosed().subscribe(res => {
			if (res) {
				this.store.dispatch(OrganizationListStateAction.loadAll());
			}
		});
	}

	/**
	 * widget & utility
	 */

	setSpecificVisibleTableColumn() {
		if (this.user.current_permission.context_application === ContextApplicationItemCodeEnum.CRM) {
			if (this.organizationLevel === OrganizationLevelEnum.LEAD) {
				this.columns.map(col => {
					col.hidden = ['vat_number_or_tax_code'].includes(col.name);
				});
			} else {
				this.columns.map(col => {
					col.hidden = ['contact_list.email'].includes(col.name);
				});
			}
		}
	}

	setRouteMetaInformation() {
		this.utilBreadcrumbService.title.value = this.utilBreadcrumbService.getBreadcrumbTitle('organizations');
		switch (this.user.current_permission.context_application) {
			case ContextApplicationItemCodeEnum.AGENT:
				this.utilBreadcrumbService.subtitle.value = this.utilBreadcrumbService.getBreadcrumbTitle(
					'organizations_organizations'
				);
				this.utilBreadcrumbService.updateActiveNavigationItemSource.next([
					'organizations',
					'organizations_organizations'
				]);
				break;
			case ContextApplicationItemCodeEnum.BACKOFFICE:
				this.utilBreadcrumbService.title.value = 'saep_ict_angular_spin8_core.page.organization.name_plural';
				this.utilBreadcrumbService.subtitle.value = this.organizationLevel;
				this.utilBreadcrumbService.updateActiveNavigationItemSource.next([
					'organizations',
					`organizations_${this.organizationLevel.toLocaleLowerCase()}`
				]);
				break;
			default:
				this.utilBreadcrumbService.subtitle.value = this.organizationLevel;
				this.utilBreadcrumbService.updateActiveNavigationItemSource.next([
					'organizations',
					`organizations_${this.organizationLevel.toLocaleLowerCase()}`
				]);
		}
	}

	async onFormValueChange(e: OrganizationListFilterCreationFieldMapFormValue) {
		if (e) {
			this.organizationListFiltered =
				await ConfigurationCustomerOrganization.returnListFilterByFormValue(
					this.user.current_permission.context_application,
					_.cloneDeep(this.organizationList),
					e
				);
			this.organizationListWrapperComponent.updateListPageBaseData(this.organizationListFiltered);
		}
	}
	exportExcel() {
		const filteredList = this.organizationListFiltered;
		if(filteredList && filteredList.length > 0) {
			this.exportService.organization_download(filteredList, this.user);
		} else {
			this.exportService.organization_download(this.organizationList, this.user);
		}
		
		
	}
}
