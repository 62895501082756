import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import _ from 'lodash';
import { UtilOrderService } from '../../../service/util/util-order.service';
import { BaseStateModel, DateMomentService, LocalListHandlerWidgetWrapper, SubscribeManagerService } from '@saep-ict/angular-core';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import {
	OrderStateModel,
	OrganizationStateModel,
	PATH_URL,
	ROUTE_URL,
	UserDetailModel
} from '@saep-ict/angular-spin8-core';
import { OrderStatusEnum } from '@saep-ict/pouch_agent_models';
import * as StatusBar from '../../../constants/configuration-customer/order/status-aggregation-map-status-bar.constant';
import { AppUtilService } from '../../../service/util/app-util.service';
import { StateFeature } from '../../../state';
import { Observable, take } from 'rxjs';
import { ViewAllButtonConfiguration } from '../../../model/util.model';
import {filter, map} from "rxjs/operators";
import {OrganizationStateAction} from "../../../state/organization/organization.actions";
import * as ConfigurationCustomerOrderDestination from '../../../constants/configuration-customer/order/order-destination.constant';
import * as ConfigurationCustomerOrderErp from '../../../constants/configuration-customer/order/order-erp.constant';
import * as ConfigurationCustomerDestination
    from "../../../constants/configuration-customer/destination/destination.constant";
import * as ConfigurationCustomerPrice from '../../../constants/configuration-customer/price/price.constant';

@Component({
	selector: 'order-list-wrapper',
	templateUrl: './order-list-wrapper.component.html',
	styleUrls: ['./order-list-wrapper.component.scss']
})
export class OrderListWrapperComponent extends LocalListHandlerWidgetWrapper implements OnInit, OnDestroy {

	@Input() title: string;
	@Input() viewAllButtonConfiguration: ViewAllButtonConfiguration;

	@Output() selectCompany: EventEmitter<{ level: string; code: string }> = new EventEmitter();

	isTablet = false;

	ROUTE_URL = ROUTE_URL;
	PATH_URL = PATH_URL;

	orderStatusEnum = OrderStatusEnum;

	organization$: Observable<BaseStateModel<OrganizationStateModel>> = this.store.select(
		StateFeature.getOrganizationState
	);
	organization: OrganizationStateModel;
	organizations: OrganizationStateModel[];

	user$: Observable<BaseStateModel<UserDetailModel>> = this.store.select(StateFeature.getUserState);
	user: UserDetailModel;


	organizationList$: Observable<BaseStateModel<OrganizationStateModel[]>> = this.store.select(
		StateFeature.getOrganizationListState
	);

	configurationCustomerOrderDestination = ConfigurationCustomerOrderDestination;
	configurationCustomerOrderErp = ConfigurationCustomerOrderErp;
	configurationCustomerPrice = ConfigurationCustomerPrice;

	constructor(
		public utilOrderService: UtilOrderService,
		public dateMomentService: DateMomentService,
		public utilService: AppUtilService,
		public translateService: TranslateService,
		public store: Store,
		private subscribeManagerService: SubscribeManagerService,
	) {
		super(utilService, translateService, store);
		this.organization$.pipe(take(1)).subscribe(res => {
			this.organization = res ? res.data : null;
		});


		this.user$.pipe(take(1)).subscribe(res => {
			if (res) {
				this.user = res.data;
			}
		});

		this.subscribeManagerService.populate(
			this.organizationList$
				.pipe(
					filter(res => !!(res && res.data)),
					map(res => {
						this.organizations = res.data
					})
				)
				.subscribe(),
			'subscribeOrganizations'
		);
	}

	ngOnInit() {
		this.checkTableColumnVisibility();
	}


	getFullOrganizationByOrderOrganization(order_organization) {
		return this.organizations.find((organization: OrganizationStateModel) => organization.code_item === order_organization.code_item) ?? order_organization;

	}

	// widget & utility
	showGenericVariationIcon(row: OrderStateModel) {
		if (
			(row && row.header && row.header.order_progress_detail && !_.isEmpty(row.header.order_progress_detail)) ||
			(row.order_variation_list && row.order_variation_list.length)
		) {
			return true;
		}
		return false;
	}

	convertMilllisStringToStringDate(stringMillis: string, format: string = 'DD/MM/YYYY'): string {
		return this.dateMomentService.convertStringDateFromFormatToFormat(stringMillis, 'x', format);
	}

	convertMillisStringToTime(stringMillis: string) {
		return this.dateMomentService.convertMillisStringToTime(stringMillis);
	}

	/**
	 * Check column visibility based on window width
	 * @param width This is the width of the window (px)
	 */
	checkTableColumnVisibility() {
		['header.date', 'header.first_evasion_date', 'header.shipped_on'].forEach(columnName => {
			this.changeVisibilityColumn(columnName, this.utilService.isMobile());
			//if(this.organization) this.changeVisibilityColumn('header.organization.business_name', true);
		});
		if (this.dataTable) {
			this.dataTable.refresh();
		}
	}

	/**
	 * Hide or show a column
	 * @param columnName the name of the column declared in the ITdDataTableColumn
	 * @param hide boolean to hide or not the column
	 */
	changeVisibilityColumn(columnName: string, hide: boolean) {
		const indexColumn = this.utilService.getElementIndex(
			this._localListHandlerWidgetWrapperColumnList,
			'name',
			columnName
		);
		if (indexColumn !== undefined) {
			this._localListHandlerWidgetWrapperColumnList[indexColumn].hidden = hide;
		}
	}

	getStatusConfig(status: OrderStatusEnum) {
		return StatusBar.statusList.find(i => i.id === status);
	}

	ngOnDestroy() {
		this.subscribeManagerService.destroy();
	}

    protected readonly ConfigurationCustomerDestination = ConfigurationCustomerDestination;
}
