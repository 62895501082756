import { Component, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import {
	BaseState,
	BaseStateModel,
	DialogConfirmComponent,
	FileTypeEnum,
	GuidFormatterPipe,
	SentencecasePipe,
	SideBarPositionValues,
	SubscribeManagerService
} from '@saep-ict/angular-core';
import {
	AngularSpin8CoreUtilTranslateService,
	ArticleEnum,
	AuxiliaryTableStateModel,
	ContextApplicationItemCodeEnum,
	ExtraFieldOrderHeaderPouchModel,
	OrderStateModel,
	OrganizationStateModel,
	PATH_URL,
	ROUTE_URL,
	UserDetailModel
} from '@saep-ict/angular-spin8-core';
import {
	ArticlePouchModel,
	OrderPouchModel,
	OrderStatusEnum,
	OrganizationPouchModel
} from '@saep-ict/pouch_agent_models';
import _ from 'lodash';
import moment from 'moment';
import { Observable } from 'rxjs';
import { filter, map, mergeMap } from 'rxjs/operators';
import * as ConfigurationCustomerOrder from '../../../../constants/configuration-customer/order/order.constant';
import * as ConfigurationSubscribeManager from '../../../../constants/subscribe-manager.constant';
import { StatusBarConfigNew } from '../../../../model/status-bar-new.model';
import { SubscribeManagerItem } from '../../../../model/subscribe-manager.model';
import { ResumeService } from '../../../../service/rest/resume.service';
import { StatusBarOrderService } from '../../../../service/status-bar-config/implementation/status-bar-order.service';
import { AppUtilService } from '../../../../service/util/app-util.service';
import { PermissionUtilService } from '../../../../service/util/permission-util.service';
import { StoreUtilService } from '../../../../service/util/store-util.service';
import { BreadcrumbTranslateItemModel, UtilBreadcrumbService } from '../../../../service/util/util-breadcrumb.service';
import { UtilOrderService } from '../../../../service/util/util-order.service';
import { StateFeature } from '../../../../state';
import { OrderActionEnum, OrderStateAction } from '../../../../state/order/order.actions';
import { OrganizationActionEnum, OrganizationStateAction } from '../../../../state/organization/organization.actions';
import { DialogUploadFileComponent } from '../../../../widget/dialog/dialog-upload-file/dialog-upload-file.component';
import { MatSnackBarWrapperComponent } from '../../../../widget/mat-snack-bar-wrapper/mat-snack-bar-wrapper.component';
import { OrderDetailAsideHelpComponent } from './aside/order-detail-aside-help/order-detail-aside-help.component';
import { OrderValidation } from '../../../../enum/order.enum';
import { BucketManagerService } from '../../../../service/util/util-bucket-manager.service';
import { OrganizationPouchModelExtraField } from '../../../../model/organization.model';
import { UtilArticleService } from '../../../../service/util/util-article.service';
import { ArticleStateAction } from '../../../../state/article/article.actions';
import { OrderPdfComponent } from '../../../../widget/pdf-print/order-pdf/order-pdf.component';
import * as ConfigurationCustomerOrderOrganization from '../../../../constants/configuration-customer/order/order-organization.constant';
import * as ConfigurationCustomerOrderHeaderSetting from '../../../../constants/configuration-customer/order/order-header-setting.constant';
import * as ConfigurationCustomerOrderNavigation from '../../../../constants/configuration-customer/order/order-navigation.constant';
import { ConnectionModel } from 'apps/spin8/src/app/model/connection.model';
import { ConnectionActionEnum } from 'apps/spin8/src/app/state/connection/connection.actions';
import { CustomerAppConfig } from 'apps/spin8/src/app/customer-app.config';

@Component({
	selector: 'order-detail',
	templateUrl: './order-detail.component.html',
	styleUrls: ['./order-detail.component.scss'],
	providers: [SubscribeManagerService]
})
export class OrderDetailComponent implements OnDestroy {
	order$: Observable<BaseStateModel<OrderStateModel>> = this.store.select(StateFeature.getOrderState);
	order: OrderStateModel;
	orderId: string = this.route.snapshot.paramMap.get('orderId');
	orderStatus: OrderStatusEnum = this.route.snapshot.paramMap.get('orderStatus') as OrderStatusEnum;

	user$: Observable<BaseStateModel<UserDetailModel>> = this.store.select(StateFeature.getUserState);
	user: UserDetailModel;

	organizationList$: Observable<BaseStateModel<OrganizationPouchModel[]>> = this.store.select(
		StateFeature.getOrganizationListState
	);
	organization$: Observable<BaseStateModel<OrganizationStateModel>> = this.store.select(
		StateFeature.getOrganizationState
	);
	organization: OrganizationPouchModelExtraField;

	auxiliaryTable$: Observable<BaseStateModel<AuxiliaryTableStateModel>> = this.store.select(
		StateFeature.getAuxiliaryTableState
	);
	auxiliaryTable: AuxiliaryTableStateModel;

	connection$: Observable<BaseStateModel<ConnectionModel>> = this.store.select(StateFeature.getConnectionState);
	offline = false;

	subscribeList: SubscribeManagerItem[] = [
		{ key: 'main-data', observable: this.subscribeMainData() },
		{ key: 'router-data', observable: this.subscribeRouterData() },
		{ key: 'connection', observable: this.subscribeConnectionData() }
	];

	statusBarConfig: StatusBarConfigNew;
	orderProductListNotFound = [];
	setTodayMinusFiveDaysDate = false;
	setProductPartiallyAvailable = false;
	setProductNotAvailable = false;
	setTodayDate = false;

	orderStatusEnum = OrderStatusEnum;
	contextApplicationItemCodeEnum = ContextApplicationItemCodeEnum;

	sideBarPositionValues = SideBarPositionValues;

	routedMainContentKey: string;
	routedSidebarContent;

	orderToAuthorize = OrderStatusEnum.TO_AUTHORIZE;

	ROUTE_URL = ROUTE_URL;

	goBackPath: string;

	constructor(
		public route: ActivatedRoute,
		public router: Router,
		private store: Store,
		private utilStoreService: StoreUtilService,
		private permissionUtilService: PermissionUtilService,
		private subscribeManagerService: SubscribeManagerService,
		private utilBreadcrumbService: UtilBreadcrumbService,
		public utilOrderService: UtilOrderService,
		private snackBar: MatSnackBar,
		private translate: TranslateService,
		private guidFormatterPipe: GuidFormatterPipe,
		public statusBardOrderService: StatusBarOrderService,
		public utilService: AppUtilService,
		private resumeService: ResumeService,
		private dialog: MatDialog,
		public bucketManager: BucketManagerService,
		private utilArticleService: UtilArticleService,
		private sentenceCasePipe: SentencecasePipe,
		private utilTranslateService: AngularSpin8CoreUtilTranslateService,
		private appConfig: CustomerAppConfig,
	) {
		this.utilOrderService.orderChangeTemp = null;
		this.loadStaticData();
		ConfigurationSubscribeManager.init(this.subscribeList, this.subscribeManagerService);
	}

	ngOnDestroy() {
		this.subscribeManagerService.destroy();
		if (!this.permissionUtilService.isOrganizationCodeItem(this.user)) {
			this.store.dispatch(OrganizationStateAction.reset());
		}
		this.store.dispatch(OrderStateAction.reset());
		this.store.dispatch(ArticleStateAction.reset());
		this.utilBreadcrumbService.unsetRouteMetaInformation();
	}

	loadStaticData() {
		this.utilStoreService.retrieveSyncState<UserDetailModel>(this.user$).subscribe(res => {
			this.user = res.data;
		});
		this.utilStoreService.retrieveSyncState<AuxiliaryTableStateModel>(this.auxiliaryTable$).subscribe(res => {
			this.auxiliaryTable = res.data;
		});
		if (!this.permissionUtilService.isOrganizationCodeItem(this.user)) {
			const organizationId = this.route.snapshot.paramMap.get('organizationId');
			this.utilStoreService.retrieveSyncState<OrganizationPouchModel[]>(this.organizationList$).subscribe(res => {
				const organization: OrganizationStateModel = res.data.find(x => x.code_item === organizationId);
				if (organization) {
					this.store.dispatch(OrganizationStateAction.updateWithArticleRecapCodeItem(new BaseState(organization)));
					// TODO: sostituire con nuovo meccanismo di dispatch
					// this.utilStoreService.dispatchArticleRecap(
					// 	organization.code_item,
					// 	organization.organization_type
					// );
				} else {
					this.router.navigate(['/not-found']);
				}
			});
		}
	}

	subscribeMainData(): Observable<void> {
		return this.organization$.pipe(
			filter(
				(store: BaseStateModel<OrganizationPouchModel>) =>
					store && store.data && store.type !== OrganizationActionEnum.LOAD
			),
			mergeMap((organization: BaseStateModel<OrganizationPouchModel>) => {
				switch (organization.type) {
					case OrganizationActionEnum.ERROR:
						throw new Error(OrganizationActionEnum.ERROR);
					case OrganizationActionEnum.UPDATE:
						this.organization = organization.data;
						break;
				}
				if (this.orderId !== 'new') {
					this.store.dispatch(OrderStateAction.load(new BaseState(this.orderId)));
				}
				return this.order$;
			}),
			filter(
				(order: BaseStateModel<OrderPouchModel<ExtraFieldOrderHeaderPouchModel>>) =>
					!!(
						this.organization &&
						order &&
						order.type &&
						order.type !== OrderActionEnum.LOAD &&
						(order.data || order.type === OrderActionEnum.ERROR || order.type === OrderActionEnum.ERROR_409)
					)
			),
			mergeMap(async (order: BaseStateModel<OrderStateModel>) => {
				this.utilArticleService.checkOrderForUpdateArticleRecap(this.order, order.data, this.organization);
				order = _.cloneDeep(order);
				await this.goBack(
					order.type,
					order.data && order.data.header ? order.data.header.status : null,
					this.routedMainContentKey
				);
				const urlStringArrayCheckout = [
					PATH_URL.PRIVATE,
					ROUTE_URL.orders,
					this.orderStatus,
					this.organization.code_item,
					order.data ? order.data._id : null, // il null rientra nel `case OrderActionEnum.ERROR`
					ROUTE_URL.checkout
				];
				switch (order.type) {
					case OrderActionEnum.UPDATE: {
						let urlStringArrayDynamic: string[];
						if (order.data._id) {
							const urlCurrentId: string = this.router.url.replace('/new/', `/${order.data._id}/`);
							const urlCurrentIdArray = urlCurrentId.split('/');
							urlCurrentIdArray.splice(0, 1);
							if (this.order && this.order._id && this.order._id !== order.data._id) {
								urlStringArrayDynamic = urlStringArrayCheckout;
							} else {
								urlStringArrayDynamic = urlCurrentIdArray;
							}
						}
						this.setOrder(order.data);
						this.setPage(order.data, urlStringArrayDynamic);
						this.showAvailabilityAlerts(this.order);
						break;
					}
					case OrderActionEnum.COMPLETED: {
						this.setPage(order.data, urlStringArrayCheckout);
						this.setOrder(order.data);
						this.showAvailabilityAlerts(this.order);
						break;
					}
					case OrderActionEnum.ERROR: {
						const snackbar = this.snackBar.open(
							this.sentenceCasePipe.transform(
								this.utilTranslateService.translate.instant('order.error.generic')
							),
							'OK',
							{ duration: 10000 }
						);
						snackbar.onAction().subscribe(() => {
							const goBackPath = ConfigurationCustomerOrderNavigation.getGoBackPath(
								this.orderStatus,
								this.user.current_permission.context_application
							);
							this.router.navigate([goBackPath]);
						});
						break;
					}
					case OrderActionEnum.ERROR_409: {
						const dialogRef: MatDialogRef<DialogConfirmComponent> = this.dialog.open(DialogConfirmComponent, {
							data: {
								text: this.sentenceCasePipe.transform(this.utilTranslateService.translate.instant('order.error.409')),
								hideCancelButton: true
							},
							panelClass: ['dialog-medium', 'michelangelo-theme-dialog'],
							disableClose: true			
						});
						dialogRef.afterClosed().subscribe(res => {
							if (res) {
								// get Detail order
								this.utilOrderService.updateArticle = true;
								this.store.dispatch(OrderStateAction.load(new BaseState(this.orderId)));
							}
						});
						break;
					}
				}
			})
		);
	}

	subscribeRouterData(): Observable<void> {
		return this.router.events.pipe(
			filter((e: RouterEvent) => !!(e && e.url && e instanceof NavigationEnd)),
			map((e: RouterEvent) => {
				this.routedMainContentKey = ConfigurationCustomerOrder.returnLastUrlSegment(e.url);
				// accedendo al dettaglio ordine tramite URL, senza il segmento `header-edit`, il router effettua
				// il redirect su `header-edit`, continuando a notificare come ultimo segmente `new`,
				// `ConfigurationCustomerOrder.detailAsideComponent.get` non è dunque in grado di restituire un componente
				// associato
				const routedSidebarContent = ConfigurationCustomerOrder.detailAsideComponent.get(
					this.routedMainContentKey
				);
				this.routedSidebarContent = routedSidebarContent ? routedSidebarContent : OrderDetailAsideHelpComponent;
			})
		);
	}

	subscribeConnectionData(): Observable<void> {
		return this.connection$.pipe(
			filter((e: BaseStateModel<ConnectionModel>) => !!(e && e.data && this.appConfig.envConfig.enableOffline)),
			map((e: BaseStateModel<ConnectionModel>) => {
				switch (e.type) {
					case ConnectionActionEnum.ERROR:
						throw new Error(ConnectionActionEnum.ERROR);
					case ConnectionActionEnum.UPDATE:
						this.offline = e.data.offline;
						break;
				}
			})
		);
	}

	setPage(order: OrderStateModel, url?: string[]) {
		this.orderStatus = order.header.status;
		this.goBackPath =
			ConfigurationCustomerOrderNavigation.getGoBackPath(
				order.header.status,
				this.user.current_permission.context_application
			);
		this.setRouteMetaInformation();
		this.statusBarConfig = this.statusBardOrderService.returnStatusBarList(
			ConfigurationCustomerOrder.statusBarMap[this.user.current_permission.context_application],
			order.header.status
		);
		if (url) {
			this.router.navigate(url);
		}
	}

	setOrder(order: OrderStateModel) {
		this.order = order;
		if (
			this.order._id &&
			this.order.header.status === OrderStatusEnum.DRAFT &&
			this.eventuallyUpdateDraftOrder()
		) {
			this.utilOrderService.orderChangeTemp = _.cloneDeep(this.order);
			this.utilOrderService.updateOrderSource.next({
				order: this.utilOrderService.orderChangeTemp,
				useLoader: true
			});
		}
	}

	eventuallyUpdateDraftOrder(): boolean {
		let diffFound = false;
		if (
			this.order.header.goods_destination_object &&
			this.order.header.goods_destination_object.code_item
		) {
			if (
				!this.organization.destination_list
				.find(i => i.code_item === this.order.header.goods_destination_object.code_item)
			) {
				ConfigurationCustomerOrderHeaderSetting.setOrderHeaderDestinationDefault(
					this.order,
					this.organization.destination_list
				);
				diffFound = true;
			}
		}
		if (this.order.header.payment_code) {
			if (!this.auxiliaryTable.paymentList.find(i => i.code_item === this.order.header.payment_code)) {
				const paymentCode =
					ConfigurationCustomerOrderOrganization.getOrganizationPreference(
						'payment_condition',
						this.auxiliaryTable.paymentList,
						this.organization
					);
				// TODO: verificare se il metodo di pagamento preferito è sempre presente
				if (paymentCode) {
					this.order.header.payment_code = paymentCode;
				} else {
					delete this.order.header.payment_code;
					delete this.order.header.payment_object;
				}
				diffFound = true;
			}
		}
		if (this.order.header.order_causal) {
			if (!this.auxiliaryTable.causalHeaderSoList.find(i => i.code_item === this.order.header.order_causal)) {
				this.order.header.order_causal =
					ConfigurationCustomerOrder.causalCode[this.user.current_permission.context_application];
				diffFound = true;
			}
		}
		const isBeforeToday: boolean =
			moment(this.order.header.first_evasion_date).diff(
				ConfigurationCustomerOrder.minDateSelectable[this.user.current_permission.context_application],
				'd',
				false
			) < 0;
		if (isBeforeToday) {
			this.order.header.first_evasion_date = moment(
				ConfigurationCustomerOrder.minDateSelectable[this.user.current_permission.context_application]
			).valueOf();
			diffFound = true;
		}
		if (diffFound) {
			ConfigurationCustomerOrderHeaderSetting.setOrderHeaderObjectForBodyTablePouchModel(
				this.order,
				this.utilOrderService.orderHeaderObjectBodyTablePouchModelList
			);
			this.snackBar.openFromComponent(MatSnackBarWrapperComponent, {
				duration: 5000,
				data: { message: this.translate.instant('order.validation.updated_data') }
			});
		}
		return diffFound;
	}

	setRouteMetaInformation() {
		let title: BreadcrumbTranslateItemModel;
		if (this.order && this.order._id) {
			title = {
				value: 'order.number',
				param: {
					codeItem: this.guidFormatterPipe.transform(this.order._id)
				}
			};
		} else {
			title = {
				value: 'order.new',
				param: null
			};
		}
		this.utilBreadcrumbService.title = title;

		this.utilBreadcrumbService.subtitle.value =
			this.organization.business_name && this.organization.code_erp
				? this.organization.business_name + ' (' + this.organization.code_erp + ')'
				: this.organization.business_name && this.organization.input_registration_code
				? this.organization.business_name + ' (' + this.organization.input_registration_code + ')'
				: this.organization.business_name;

		const statusRelatedMap =
			ConfigurationCustomerOrder.statusAggregationMap[
				ContextApplicationItemCodeEnum[this.user.current_permission.context_application]
			];
		for (const aggregation of statusRelatedMap) {
			if (aggregation.related_list.includes(this.order.header.status)) {
				this.utilBreadcrumbService.updateActiveNavigationItemSource.next([
					'orders',
					`orders_${aggregation.state.toLocaleLowerCase()}`
				]);
				break;
			}
		}
	}

	showAvailabilityAlerts(order: OrderStateModel) {
		const orderProductList: ArticlePouchModel[] = [];
		order.product_list.forEach(product =>
			product ? orderProductList.push(product) : this.orderProductListNotFound.push(product)
		);
		this.setTodayMinusFiveDaysDate =
			!order.header.first_evasion_date || moment(order.header.first_evasion_date).diff(moment(), 'd') < 5;
		this.setProductPartiallyAvailable =
			orderProductList.findIndex(
				prod => prod.articleDescription?.cf_available === ArticleEnum.Status.Available.LOW
			) !== -1;
		this.setProductNotAvailable =
			orderProductList.findIndex(
				prod => prod.articleDescription?.cf_available === ArticleEnum.Status.Available.NOT_AVAILABLE
			) !== -1;
		if (order.header.first_evasion_date) {
			const orderCompositionDate = moment(this.order.header.date, 'DD/MM/YYYY');
			const isSameDay = orderCompositionDate.diff(moment(order.header.first_evasion_date), 'd', true) === 0;
			const isBeforeMidday = ConfigurationCustomerOrder.today.getHours() - 12 < 0;

			if (isSameDay && isBeforeMidday) {
				this.setTodayDate = true;
			} else {
				this.setTodayDate = false;
			}
		}
	}

	generatePDF() {
		this.dialog.open(OrderPdfComponent, {
			data: {
				title: 'Print PDF',
				order: this.order,
				context_application: this.user.current_permission.context_application
			},
			panelClass: ['dialog-small', 'michelangelo-theme-dialog'],
			disableClose: false
		});
	}
	confirmOrder() {
		this.resumeService.confirmOrder(this.order, { document_type: 'order' }).then(res => {
			if (res.message === OrderValidation.ORDER_NOT_VALIDATED) {
				const message = this.translate.instant('order.validation.not_valid_order');
				this.snackBar.open(message, null, { duration: 3000 });
			} else {
				const message = this.translate.instant('order.validation.valid_order');
				this.snackBar.open(message, null, { duration: 3000 });
			}
		});
	}

	/**
	 * Insieme di controlli che possono determinare se riportare l'utente alla lista. Evita che l'ordine venga mostrato
	 * su schermate non idonee al suo status oppure ordini eliminati o inesistenti.
	 * @param storeActionType
	 * @param orderStatus
	 * @param routedMainContentKey
	 */
	goBack(storeActionType: string, orderStatus: OrderStatusEnum, routedMainContentKey: string): Promise<void> {
		return new Promise(resolve => {
			if (
				storeActionType === OrderActionEnum.REMOVED ||
				(orderStatus !== OrderStatusEnum.DRAFT &&
					(routedMainContentKey === ROUTE_URL.catalog || routedMainContentKey === 'header-edit')) // TODO: trasferire insieme agli altri segmenti
			) {
				const goBakPath =
					ConfigurationCustomerOrderNavigation.getGoBackPath(
						this.orderStatus,
						this.user.current_permission.context_application
					);
				this.router.navigate([goBakPath]);
			} else {
				resolve();
			}
		});
	}

	openDialogCalcSheetArticleListUpdateOrder() {
		const dialogRef = this.dialog.open(DialogUploadFileComponent, {
			data: {
				acceptedType: FileTypeEnum.EXCEL
			},
			disableClose: true,
			panelClass: ['dialog-normal', 'michelangelo-theme-dialog']
		});
		dialogRef.afterClosed().subscribe(e => {
			if (e) {
				this.utilOrderService.updateArticle = true;
				this.utilOrderService.calcSheetArticleListUpdateOrder(e, this.organization, this.order);
			}
		});
	}

	forwardOrder() {
		if (this.routedMainContentKey === ROUTE_URL.checkout) {

			this.utilOrderService.forwardOrder(this.order, this.organization);
		} else {
			// navigate to checkout
			this.router.navigate([ROUTE_URL.checkout], { relativeTo: this.route });
		}
	}

}
