import { Inject, Injectable } from '@angular/core';
import { ISaepIctAngularCoreConfigModel } from '@saep-ict/angular-core';
import { ContextApplicationItemCodeEnum, TokenPayload } from '@saep-ict/angular-spin8-core';
import { IMediaCenterConfigModel } from '@saep-ict/media-center';
import { ConfigCouchModel, IPouchAppConfig, IPouchConfigModel } from '@saep-ict/pouch-db';
import { ITicketCenterConfigModel } from '@saep-ict/ticket-center';
import { LocalStorage } from 'ngx-webstorage';
import { BehaviorSubject } from 'rxjs';
import * as ConfigurationCustomerTicketCenter from './constants/configuration-customer/ticket-center/ticket-center.constant';
import { Environment } from './enum/environment.enum';
import {
	CompanyConfigModel,
	ConfigModel,
	UrlConfigModel,
	UsefulLinksModel
} from './model/structure/environment-config.model';
import { KeycloakConfig } from 'keycloak-js';
import * as ConfigurationCustomerAppStructure from './constants/configuration-customer/app-structure/app-structure.constant';

@Injectable({
	providedIn: 'root'
})
export class CustomerAppConfig implements IPouchAppConfig<CustomerAppConfigModel> {
	@LocalStorage('payload')
	private _tokenPayload: TokenPayload;

	@LocalStorage('authenticationToken')
	authenticationToken: string;

	get username(): string {
		return this._tokenPayload && this._tokenPayload.username;
	}
	get signature(): string {
		return this._tokenPayload && this._tokenPayload.signature;
	}
	get config(): CustomerAppConfigModel {
		return this.configSource.getValue();
	}
	set config(val: CustomerAppConfigModel) {
		this.configSource.next(val);
	}
	configSource = new BehaviorSubject<CustomerAppConfigModel>(undefined as CustomerAppConfigModel);
	config$ = this.configSource.asObservable();
	set tag(val: string) {
		const config = this.config;
		config.tag = val;
		this.config = config;
	}
	set release(val: string) {
		const config = this.config;
		config.release = val;
		this.config = config;
	}
	get token(): string {
		return this.authenticationToken;
	}

	get documentSeparator(): string {
		return ConfigurationCustomerAppStructure.noSqlDocSeparator;
	}

	// lib
	libToInitialize: string[] = [
		'saepIctAngularCore',
		'saepIctAngularSpin8Core',
		'ticket-center',
		'angularFlaskIntegration'
	];
	libInitialized: string[] = [];
	libInitializationDone = new BehaviorSubject<boolean>(false);
	libInitializationDone$ = this.libInitializationDone.asObservable();

	// lib - media-center
	private _mc_config: IMediaCenterConfigModel;
	set mc_config(val: IMediaCenterConfigModel) {
		this._mc_config = val;
	}
	get mc_config(): IMediaCenterConfigModel {
		return this._mc_config;
	}
	// lib - ticket-center
	private _tc_config: ITicketCenterConfigModel;
	set tc_config(val: ITicketCenterConfigModel) {
		this._tc_config = val;
	}
	get tc_config(): ITicketCenterConfigModel {
		return this._tc_config;
	}
	// lib - angular-spin8-core
	private _saepIctAngularSpin8Core_config: ISaepIctAngularCoreConfigModel;
	set saepIctAngularSpin8Core_config(val: ISaepIctAngularCoreConfigModel) {
		this._saepIctAngularSpin8Core_config = val;
	}
	get saepIctAngularSpin8Core_config(): ISaepIctAngularCoreConfigModel {
		return this._saepIctAngularSpin8Core_config;
	}
	// lib - angular-core
	private _saepIctAngularCore_config: ISaepIctAngularCoreConfigModel;
	set saepIctAngularCore_config(val: ISaepIctAngularCoreConfigModel) {
		this._saepIctAngularCore_config = val;
	}
	get saepIctAngularCore_config(): ISaepIctAngularCoreConfigModel {
		return this._saepIctAngularCore_config;
	}
	// lib - angular-flask-integration
	private _angularFlaskIntegration_config;
	set angularFlaskIntegration_config(val) {
		this._angularFlaskIntegration_config = val;
	}
	get angularFlaskIntegration_config() {
		return this._angularFlaskIntegration_config;
	}
	// lib - media-center
	initializeConfig(media_center_config: IMediaCenterConfigModel): void {
		this.mc_config = media_center_config;
		this.isEveryLibInizialized('media-center');
	}
	// lib - ticket-center
	initializeConfigTicketCenter(config: ITicketCenterConfigModel): void {
		this.tc_config = config;
		this.tc_config.customConfiguration = ConfigurationCustomerTicketCenter.configuration;
		this.isEveryLibInizialized('ticket-center');
	}
	// lib - angular-spin8-core
	initializeSaepIctAngularSpin8Core(config: ISaepIctAngularCoreConfigModel): void {
		this.saepIctAngularSpin8Core_config = config;
		this.isEveryLibInizialized('saepIctAngularSpin8Core');
	}
	// lib - angular-core
	initializeSaepIctAngularCore(config: ISaepIctAngularCoreConfigModel): void {
		this.saepIctAngularCore_config = config;
		this.isEveryLibInizialized('saepIctAngularCore');
	}
	// lib - angular-flask-integration
	initializeAngularFlaskIntegration(config): void {
		this.angularFlaskIntegration_config = config;
		this.isEveryLibInizialized('angularFlaskIntegration');
	}

	constructor(@Inject('env-config') public envConfig: CustomerAppConfigModel) {
		if (this.envConfig) {
			this.populateConfig(this.envConfig);
		}
	}

	populateConfig(configuration: CustomerAppConfigModel) {
		this.config = configuration;
	}

	isEveryLibInizialized(lib: string) {
		this.libInitialized.push(lib);
		if (this.libToInitialize.every(i => this.libInitialized.includes(i))) {
			this.libInitializationDone.next(true);
		}
	}
}

export interface KeyCloackLogoutConfig extends KeycloakConfig{
	logout: string;
}

export class CustomerAppConfigModel implements IPouchConfigModel, ConfigModel {
	sso: KeyCloackLogoutConfig;
	couch: ConfigCouchModel[];
	company: CompanyConfigModel;
	permissionContext: ContextApplicationItemCodeEnum[];
	environment: Environment;
	urlConfig: UrlConfigModel;
	tag: string;
	release: string;
	enableOffline: boolean;
	googleTagManagerId: string;
	usefulLinks: UsefulLinksModel;
	euroitaliaB2bOldAppIframeUrl: string;
	bucketManager: {
		be_url: string;
	};
	chat: {
		url: string;
		pdf: string;
		requestCenter: string;
	}
}
