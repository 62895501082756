import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { AttachmentResolverComponent } from './attachment-resolver/attachment-resolver.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { ChatThreadComponent } from './chat-thread/chat-thread.component';
import { DropAreaFileComponent } from './drop-area-file/drop-area-file.component';
import { ListWrapperComponent } from './list-wrapper/list-wrapper.component';
import { TabWrapperComponent } from './tab-wrapper/tab-wrapper.component';
import { TableColumnToggleComponent } from './table-column-toggle/table-column-toggle.component';
import { PreviewContainerComponent } from './preview-container/preview-container.component';
import { DialogConfirmComponent } from './dialog-confirm/dialog-confirm.component';
import { DialogImageComponent } from './dialog-image/dialog-image.component';
import { DialogTextEditComponent } from './dialog-text-edit/dialog-text-edit.component';
import { MaterialComponentsModule } from '../material-components.module';
import { PipeModule } from '../pipes/pipe.module';
import { DirectivesModule } from '../directives/directives.module';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MatDialogWrapperComponent } from './dialog/mat-dialog-wrapper/mat-dialog-wrapper.component';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { NgScrollbarReachedModule } from 'ngx-scrollbar/reached-event';
import { FormControlMultipurposeComponent } from './form/form-control-multipurpose/form-control-multipurpose.component';
import { FormDynamicComponent } from './form/form-dynamic/form-dynamic.component';
import { FormButtonBarComponent } from './form-button-bar/form-button-bar.component';
import { PersonCardComponent } from './person-card/person-card.component';
import { AttachmentListWrapperComponent } from './attachment-list-wrapper/attachment-list-wrapper.component';
import { AttachmentListManagerComponent } from './attachment-list-manager/attachment-list-manager.component';
import { CovalentModule } from '../covalent.module';
import { LoadingOverlayComponent } from './loading-overlay/loading-overlay.component';
import { QuillModule } from 'ngx-quill';
import { ProcessQueueComponent } from './process-queue/process-queue.component';
import { PageWithSidebarComponent } from './page-with-sidebar/page-with-sidebar.component';
import { SidebarContentWrapperComponent } from './sidebar-content-wrapper/sidebar-content-wrapper.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        MaterialComponentsModule,
        TranslateModule,
        PipeModule,
        DirectivesModule,
        NgScrollbarModule,
        NgScrollbarReachedModule,
        CovalentModule,
        QuillModule
    ],
    declarations: [
        LoadingOverlayComponent,
        AttachmentListManagerComponent,
        AttachmentListWrapperComponent,
        AttachmentResolverComponent,
        BreadcrumbComponent,
        ChatThreadComponent,
        DropAreaFileComponent,
        ListWrapperComponent,
        TabWrapperComponent,
        TableColumnToggleComponent,
        FormButtonBarComponent,
        PersonCardComponent,
        PreviewContainerComponent,
        // dialog
        DialogConfirmComponent,
        DialogImageComponent,
        DialogTextEditComponent,
        MatDialogWrapperComponent,
        // form
        FormControlMultipurposeComponent,
        FormDynamicComponent,
        // process-queue per download
        ProcessQueueComponent,
        PageWithSidebarComponent,
        SidebarContentWrapperComponent
    ],
    exports: [
        LoadingOverlayComponent,
        AttachmentListManagerComponent,
        AttachmentListWrapperComponent,
        AttachmentResolverComponent,
        BreadcrumbComponent,
        ChatThreadComponent,
        DropAreaFileComponent,
        ListWrapperComponent,
        TabWrapperComponent,
        TableColumnToggleComponent,
        FormButtonBarComponent,
        PersonCardComponent,
        PreviewContainerComponent,
        // dialog
        DialogConfirmComponent,
        DialogImageComponent,
        DialogTextEditComponent,
        MatDialogWrapperComponent,
        // form
        FormControlMultipurposeComponent,
        FormDynamicComponent,
        ProcessQueueComponent,
        PageWithSidebarComponent,
        SidebarContentWrapperComponent
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class WidgetModule {}
