import { Component, OnDestroy, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TdPagingBarComponent } from '@covalent/core/paging';
import { Store } from '@ngrx/store';
import {
	BaseState,
	BaseStateModel,
	SentencecasePipe,
	SubscribeManagerService
} from '@saep-ict/angular-core';
import { Observable } from 'rxjs';
import { filter, map, mergeMap, take } from 'rxjs/operators';
import { StateFeature } from '../../../state';
import { OpportunityStateAction, OpportunityActionEnum } from '../../../state/opportunity/opportunity.actions';
import * as _ from 'lodash';
import { OpportunityListWrapperComponent } from '../../../widget/opportunity/opportunity-list-wrapper/opportunity-list-wrapper.component';
import { OrganizationListStateAction } from '../../../state/common/organization-list/organization-list.actions';
import { AppUtilService } from '../../../service/util/app-util.service';
import { LocalListHandlerBaseModel, ContactPouchModel } from '@saep-ict/pouch_agent_models';
import { MatDialog } from '@angular/material/dialog';
import { DialogContactDetailComponent } from '../../../widget/dialog/dialog-contact-detail/dialog-contact-detail.component';
import * as ConfigurationCustomerOrganization from '../../../constants/configuration-customer/organization/organization.constant';
import { ContactStateAction } from '../../../state/contact/contact.actions';
import { TranslateService } from '@ngx-translate/core';
import { OpportunityPouch } from '../../../service/pouch-db/spin8/pouch-function/opportunity.pouch';
import { DialogActionListComponent } from '../../../widget/dialog/dialog-action-list/dialog-action-list.component';
import {
	ContextApplicationItemCodeEnum,
	OpportunityEnum,
	PATH_URL,
	ROUTE_URL,
	UserDetailModel,
	OrganizationStateModel,
	AngularSpin8CoreUtilCompanyService,
	OpportunityPouchModel
} from '@saep-ict/angular-spin8-core';

import { UtilBreadcrumbService } from '../../../service/util/util-breadcrumb.service';
import { FramePageComponent } from '../../../frame/admin/admin.component';
import * as ConfigurationCustomerOpportunityColumns from '../../../constants/configuration-customer/opportunity/opportunity-column-map/opportunity-column-map-base.constant';

@Component({
	selector: 'opportunity',
	templateUrl: './opportunity.component.html',
	styleUrls: ['./opportunity.component.scss'],
	providers: [SubscribeManagerService]
})
export class OpportunityComponent implements OnDestroy {
	@ViewChild('pagingBarPageSize', { static: false }) public pagingBarPageSize: TdPagingBarComponent;
	@ViewChild(OpportunityListWrapperComponent)
	public opportunityListWrapperComponent: OpportunityListWrapperComponent;

	opportunity$: Observable<BaseStateModel<OpportunityPouchModel[]>> = this.store.select(
		StateFeature.getOpportunityState
	);
	opportunity: OpportunityPouchModel[];
	public opportunityLevel: string;

	user$: Observable<BaseStateModel<UserDetailModel>> = this.store.select(StateFeature.getUserState);
	user: UserDetailModel;

	organizationList$: Observable<BaseStateModel<OrganizationStateModel[]>> = this.store.select(
		StateFeature.getOrganizationListState
	);
	organizationList: OrganizationStateModel[];

	OpportunityEnum = OpportunityEnum;

	// enum
	contextApplicationItemCodeEnum = ContextApplicationItemCodeEnum;

	public columns = ConfigurationCustomerOpportunityColumns.baseColumns;

	public listPageBaseData = <LocalListHandlerBaseModel<OpportunityPouchModel>>{
		pagination: {
			pageSize: this.contextApplicationItemCodeEnum.CRM ? 25 : 10
		},
		filters: {
			localSearchText: {
				value: null,
				key_list: [
					'title',
					'organization.business_name',
					'contact_name',
					'assegnee_user_name',
					'status_code',
					'actual_value',
					'context_application',
					'opportunity_type'
				]
			}
		},
		sort: {
			name: 'date_creation',
			order: 'DESC'
		},
		data: []
		// languageKey: 'it'
	};

	formFilters: FormGroup;

	public idOrganization = this.activatedRoute.parent.snapshot.paramMap.get('idOrganization');

	// Check if some filter is active
	hasChange = false;

	constructor(
		private store: Store,
		private router: Router,
		private subscribeManagerService: SubscribeManagerService,
		public utilCompanyService: AngularSpin8CoreUtilCompanyService,
		private activatedRoute: ActivatedRoute,
		private fb: FormBuilder,
		private utilService: AppUtilService,
		private dialog: MatDialog,
		private sentencecasePipe: SentencecasePipe,
		private translateService: TranslateService,
		private utilBreadcrumbService: UtilBreadcrumbService
	) {
		this.user$.pipe(take(1)).subscribe(res => {
			this.user = res ? res.data : null;
		});
		this.store.dispatch(OrganizationListStateAction.loadAll());
		this.store.dispatch(OpportunityStateAction.loadAll());
		this.createFormFilters();

		// handle column visibility
		if (this.idOrganization) {
			this.columns.map(col => {
				col.hidden = ['context_application', 'status_code'].includes(col.name);
			});
		}
		// if(this.opportunityLevel === OpportunityEnum.Level.LEAD){
		// 	this.columns.map(col => {
		// 		col.hidden = ['organization'].includes(col.name);
		// 	});
		// }

		this.subscribeManagerService.populate(this.subscribeOpportunityList().subscribe(), 'subscribeOpportunityList');
		//this.utilBreadcrumbService.title = this.translateService.instant((this.opportunityLevel === OpportunityEnum.Level.LEAD ? 'request.name_plural' : 'opportunity.name'));
		this.setRouteMetaInformation();
	}

	ngOnDestroy() {
		this.store.dispatch(OpportunityStateAction.reset());
		this.subscribeManagerService.destroy();
		this.unsetRouteMetaInformation();
	}

	/**
	 * Subscription
	 */

	subscribeOpportunityList() {
		return this.organizationList$.pipe(
			filter(res => !!(res && res.data)),
			mergeMap(res => {
				this.organizationList = res.data;
				return this.activatedRoute.paramMap;
			}),
			mergeMap(params => {
				this.opportunityLevel = params.get('opportunityLevel')?.toLowerCase() || null;
				return this.opportunity$;
			}),
			filter((state: BaseStateModel<OpportunityPouchModel[]>) => !!(state && state.data)),
			map((state: BaseStateModel<OpportunityPouchModel[]>) => {
				switch (state.type) {
					case OpportunityActionEnum.UPDATE:
						this.applyPermanentFilters(state.data);
						break;

					case OpportunityActionEnum.ERROR:
						throw new Error(OpportunityActionEnum.ERROR);

					default:
						break;
				}

				return state;
			})
		);
	}

	applyPermanentFilters(opportunityList: OpportunityPouchModel[]) {
		switch (this.user.current_permission.context_application) {
			case ContextApplicationItemCodeEnum.CRM: {
				if (this.idOrganization) {
					opportunityList = this.getListFilteredByOrganization(opportunityList);
				} else {
					opportunityList = this.getListFilteredByType(opportunityList);
				}
				opportunityList = this.getOrganization(opportunityList);
				break;
			}
			case ContextApplicationItemCodeEnum.AGENT: {
				break;
			}
			case ContextApplicationItemCodeEnum.B2B: {
				break;
			}
			case ContextApplicationItemCodeEnum.BACKOFFICE: {
				break;
			}
		}

		this.opportunity = opportunityList;
		// filtri sidebar
		this.onFormFiltersSubmit();
	}

	/**
	 * Filters sidebar
	 */

	createFormFilters() {
		this.formFilters = this.fb.group({
			status_open: false,
			manageable: false
		});

		this.utilService.onCreateFormValueChange(this.formFilters).subscribe(val => (this.hasChange = val));

		this.formFilters.patchValue({
			status_open: true,
			manageable: true
		});
	}

	resetFilters() {
		this.formFilters.patchValue({
			status_open: false,
			manageable: false
		});
		this.onFormFiltersSubmit();
	}

	onFormFiltersSubmit() {
		let filteredList = _.cloneDeep(this.opportunity);

		if (this.formFilters && this.formFilters.get('status_open').value) {
			filteredList = filteredList.filter(
				opportunity => opportunity.status_code === OpportunityEnum.Status.Outcome.Open
			);
		}

		if (this.formFilters && this.formFilters.get('manageable').value) {
			filteredList = filteredList.filter(opportunity => opportunity.manageable);
		}

		this.opportunityListWrapperComponent.updateListPageBaseData(filteredList);
	}

	/**
	 * navigation
	 */

	goToOpportunityDetail(opportunity: OpportunityPouchModel) {
		this.router.navigate([ROUTE_URL.private, ROUTE_URL.opportunity, opportunity.level, opportunity._id]);
	}

	new() {
		// http://localhost:4200/dashboard/opportunity/lead/opportunity_14528bb6-0f14-4f8e-741a-ecfa602878f3/detail
		this.router.navigate([
			ROUTE_URL.private,
			ROUTE_URL.opportunity,
			OpportunityEnum.Level.LEAD,
			ROUTE_URL.newOpportunity
		]);
	}

	// CRM filter by type
	getListFilteredByType(opportunityList: OpportunityPouchModel[]) {
		return opportunityList.filter(opportunity => opportunity.level == this.opportunityLevel);
	}

	// CRM filter by type
	getListFilteredByOrganization(opportunityList: OpportunityPouchModel[]) {
		return opportunityList.filter(opportunity => {
			if (opportunity && opportunity.organization && opportunity.organization.code_item) {
				return opportunity.organization.code_item === this.idOrganization;
			}
			return false;
		});
	}

	// CRM filter by type
	getOrganization(opportunityList: OpportunityPouchModel[]) {
		return opportunityList.map(opportunity => {
			const founded = this.organizationList.find(org => org.code_item == opportunity.organization.code_item);
			opportunity.organization.code_item = founded?.code_item;
			opportunity.organization.business_name = founded?.business_name;
			opportunity.organization.level = founded?.level;
			// TODO: chiarire obbligatorietà di contact_list / contact
			opportunity['contact'] = founded?.contact_list?.find(c => c.code_item == opportunity.contact_id);
			return opportunity;
		});
	}

	selectCompany(level: string, code: string) {
		this.utilCompanyService.navigateToCompanyDetail(level, code);
	}

	openContactDetail(opportunity: OpportunityPouchModel) {
		this.router.navigate([
			`${PATH_URL.PRIVATE}/${ROUTE_URL.organizations}/customer/${opportunity.organization.code_item}/${ROUTE_URL.contacts}`
		]);
		// const dialog = this.dialog.open(DialogContactDetailComponent, {
		// 	data: {
		// 		title: this.sentencecasePipe.transform(this.translateService.instant('contact.edit')),
		// 		organizationCodeItem: this.idOrganization,
		// 		canEdit: ConfigurationCustomerOrganization.canEdit.contact,
		// 		contact: opportunity['contact']
		// 	},
		// 	panelClass: 'dialog-medium',
		// 	minHeight: '32vh',
		// 	disableClose: true
		// });
		// dialog.afterClosed().subscribe((contactToSave: ContactPouchModel) => {
		// 	if (contactToSave) {
		// 		this.updateContact(contactToSave);
		// 	}
		// });
	}

	openDialogActionList(opportunity: ContactPouchModel) {
		const dialog = this.dialog.open(DialogActionListComponent, {
			data: {
				modalTitle: this.sentencecasePipe.transform(this.translateService.instant('action.show')),
				opportunity: opportunity
			},
			panelClass: 'dialog-medium',
			minHeight: '32vh',
			disableClose: true
		});
		dialog.afterClosed().subscribe((contactToSave: ContactPouchModel) => {
			if (contactToSave) {
				this.updateContact(contactToSave);
			}
		});
	}

	updateContact(row: ContactPouchModel) {
		this.store.dispatch(ContactStateAction.save(new BaseState(row)));
	}

	openAddAction(row: ContactPouchModel) {
		this.router.navigate([`${PATH_URL.PRIVATE}/${ROUTE_URL.actions}/${ROUTE_URL.newAction}`], {
			queryParams: { contact_id: row.code_item }
		});
	}

	setRouteMetaInformation() {
		if (!this.activatedRoute.parent.component || this.activatedRoute.parent.component === FramePageComponent) {
			this.utilBreadcrumbService.title.value = this.utilBreadcrumbService.getBreadcrumbTitle(
				this.opportunityLevel === OpportunityEnum.Level.LEAD ? 'request_list' : 'opportunity'
			);
		}
	}

	unsetRouteMetaInformation() {
		if (!this.activatedRoute.parent.component || this.activatedRoute.parent.component === FramePageComponent) {
			this.utilBreadcrumbService.unsetRouteMetaInformation();
		}
	}
}
