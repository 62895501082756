<page-with-sidebar [isRoot]="true" [sideBarPosition]="sideBarPositionValues.TOP">
	<ng-container pageMain>
		<mat-card class="michelangelo-theme">
			<organization-list-wrapper
				[localListHandlerWidgetWrapperData]="listPageBaseData"
				[localListHandlerWidgetWrapperColumnList]="columns"
				[localListHandlerTextFilterShow]="true"
				[localListHandlerPaginatorShow]="true"
				(onItemSelect)="goToDetail($event.level, $event.code)"
				(openOrderNew)="orderDraftCreate($event)"
				(createNew)="createNew()"
				[localListHandlerWidgetWrapperCanCreate]="configurationCustomerOrganization.canCreate.detail[
							contextApplicationItemCodeEnum[user.current_permission.context_application]
				]"
			>
			</organization-list-wrapper>
		</mat-card>
	</ng-container>
	<ng-container pageSide>
		<sidebar-content-wrapper>
			<ng-container main-content>
				<form-dynamic
				[configuration]="formDynamicConfiguration"
				(valueChange)="onFormValueChange($event)"
				></form-dynamic>
					<button
						*ngIf="configurationCustomerCalcSheet.enableExportOrder[user.current_permission.context_application]"
						class="utility-button"
						(click)="exportExcel()"
					>
						<mat-icon class="download">file_download</mat-icon>
						<span>{{ 'general.export_xls' | translate | sentencecase }}</span>
					</button>
			</ng-container>
		</sidebar-content-wrapper>
	</ng-container>
	<!-- <ng-container pageSide>
		<button class="export-button" (click)="exportExcel()">Export Excel</button>
	</ng-container> -->
</page-with-sidebar>

<!--<button-->
<!--	mat-fab-->
<!--	*ngIf="-->
<!--		configurationCustomerOrganization.canCreate.detail[-->
<!--			contextApplicationItemCodeEnum[user.current_permission.context_application]-->
<!--		]-->
<!--	"-->
<!--	(click)="createNew()"-->
<!--	[matTooltip]="'organization.add_new' | translate | sentencecase"-->
<!--	matTooltipPosition="left"-->
<!--	class="mat-fab-button"-->
<!--	color="primary"-->
<!--&gt;-->
<!--	<mat-icon>add</mat-icon>-->
<!--	<span class="label">{{ 'organization.add_new' | translate | sentencecase }}</span>-->
<!--</button>-->
