<mat-divider class="both-spacing"></mat-divider>
<mat-toolbar *ngIf="localListHandlerTextFilterShow || localListHandlerTitle !== ''">
  <mat-toolbar-row *ngIf="localListHandlerTitle !== ''">
    <span class="title">{{ localListHandlerTitle | translate | sentencecase }}</span>
    <span class="badge" [class.ml-3]="localListHandlerTitle">
      {{ localListHandlerData?.data?.length || 0 }}
    </span>
  </mat-toolbar-row>
  <mat-toolbar-row *ngIf="localListHandlerTextFilterShow">
    <td-search-box
      *ngIf="localListHandlerData && localListHandlerData.filters && localListHandlerData.filters.localSearchText"
      backIcon="search"
      [(ngModel)]="localListHandlerData.filters.localSearchText.value"
      [showUnderline]="true"
      [debounce]="500"
      [alwaysVisible]="true"
      (searchDebounce)="localListHandlerApplyFilterCustom()"
    >
    </td-search-box>
    <button
      *ngIf="localListHandlerSidebarFilterShow"
      mat-icon-button
      (click)="localListHandlerOpenSidebarFilter.emit()"
      type="button"
    >
      <mat-icon>filter_list</mat-icon>
    </button>
  </mat-toolbar-row>
</mat-toolbar>
<ng-container
    *ngIf="
      configuration &&
      localListHandlerData && localListHandlerData.dataSubset && localListHandlerData.dataSubset.length > 0;
      else noData
    "
  >
  <td-data-table
    #dataTable
    [data]="localListHandlerData.dataSubset"
    [columns]="_localListHandlerWidgetWrapperColumnList"
    [multiple]="true"
    [selectable]="true"
    [(ngModel)]="itemListSelected"
    [sortable]="true"
    (sortChange)="localListHandlerApplyFilterCustom({ sort: $event })"
    [sortBy]="localListHandlerData.sort && localListHandlerData.sort.name ? localListHandlerData.sort.name : null"
    [sortOrder]="localListHandlerData.sort && localListHandlerData.sort.order ? localListHandlerData.sort.order : null"
  >
    <!-- preview -->
    <ng-template
      tdDataTableTemplate="preview"
      let-row="row"
    >
      <preview-container
        [configuration]="
          {
            image: row.bucket_link_thumbnail,
            icon: 'attach_file',
            width: '30px',
            height: '30px',
            onClickHandler: true
          }
        "
        (onClick)="onItemPreviewSelect.emit(row)"
      >
      </preview-container>
    </ng-template>
    <!-- name  da cui può partire il dowload dell'immagine nel tag <a>...</a> [attr.href]="row.bucket_link"-->
    <ng-template
      tdDataTableTemplate="name"
      let-row="row"
    >
      <p>
        <a 
          *ngIf="
            configuration &&
            configuration.privilege &&
            configuration.privilege.download;
            else rowName
          "
          target="_blank"
          [attr.href]="row.bucket_link"
        >
          <ng-container *ngTemplateOutlet="rowName"></ng-container>
        </a>
      </p>
      <ng-template #rowName>{{ row.name }}</ng-template>
    </ng-template>
    <!-- action = gestione pulsanti dowload/delete/selezione multipla -->
    <ng-template
      tdDataTableTemplate="action"
      let-row="row"
    >
      <div class="action">
        <!-- mat-icon class="..." gestiti nel file michelangelo-theme.scss -->
        <a
          *ngIf="configuration && configuration.privilege && configuration.privilege.download"
          target="_blank"
          [attr.href]="row.bucket_link"
        >
          <button
            mat-icon-button
            (click)="onItemSelect.emit(row)"
            type="button"
          >
            <mat-icon class="download">download</mat-icon>
          </button>
        </a>
        <button
          mat-icon-button
          *ngIf="configuration && configuration.privilege && configuration.privilege.delete"
          (click)="onItemDelete.emit(row)"
          type="button"
        >
          <mat-icon class="delete">delete</mat-icon>
        </button>
      </div>
    </ng-template>
  </td-data-table>
  <button
    *ngIf="itemListSelected && itemListSelected.length > 0"
    mat-button
    (click)="onDownloadFromListSelected.emit(itemListSelected)"
    type="button"
  >
    {{ 'saep_ict_angular_core.general.download_selected' | translate | sentencecase }}
  </button>
  <mat-toolbar *ngIf="localListHandlerData.pagination && localListHandlerPaginatorShow">
    <mat-toolbar-row>
      <span class="flex-span"></span>
      <mat-paginator
        *ngIf="localListHandlerData.pagination && localListHandlerPaginatorShow"
        #matPaginator
        (page)="localListHandlerApplyFilterCustom({ pagination: $event })"
        [length]="localListHandlerData.pagination.totalFilteredItemCount"
        [pageSize]="localListHandlerData.pagination.pageSize"
        [pageSizeOptions]="[5, 10, 25, 100]"
      >
      </mat-paginator>
    </mat-toolbar-row>
  </mat-toolbar>
</ng-container>
<loading-results-spinner *ngIf="isLoading"></loading-results-spinner>
<ng-template #noData>
  <p
    class="placeholder"
    *ngIf="!isLoading"
  >
    {{ 'saep_ict_angular_core.general.no_results' | translate | sentencecase }}
  </p>
</ng-template>