import {
	ArticlePouchModel,
	BodyTablePouchModel,
	DestinationPouchModel,
	DiscountModel,
	HeaderPouchModel,
	OrderPouchModel,
	OrderProgressPouchModel,
	OrderStatusEnum,
	CurrencyPouchModel,
  Category,
  OrderAddressPouchModel
} from '@saep-ict/pouch_agent_models';

import { OrganizationPouchModel } from '@saep-ict/pouch_agent_models';
import { DiscrepancyOrderEnum } from '../../enum/order/discrepancy-order.enum';
import { AdditionalServiceHeader } from '../state/additional-service-state.model';
import { ProductVariationTypeEnum } from '../../enum/product-variation.enum';
import { CarrierPumpingChargePouchModel } from '../state/auxiliary-table-list.model';
import { OrganizationStateModel } from '../../model/state/organization-state.model';

export class OrderStateModel implements OrderPouchModel<ExtraFieldOrderHeaderPouchModel> {
	_id?: string;
	_rev?: string;
	type: string;
	order_type?: string;
	header?: HeaderStateModel = new HeaderStateModel();
	product_list?: ArticlePouchModel[] = [];
	order_progress?: OrderProgressPouchModel<ExtraFieldOrderHeaderPouchModel>;
	order_variation_list?: ProductVariationStateModel[];
	csuite?: CsuiteStateModel;
	code_item?: string;
	source?: string;
}

export class HeaderStateModel implements HeaderPouchModel<ExtraFieldOrderHeaderPouchModel> {
	additional_services?: AdditionalServiceHeader;
	agent?: BodyTablePouchModel;
	authorized?: Authorized;
	bank_object?: BankObject;
	calculated_km?: number;
	carrier?: BodyTablePouchModel;
	client_code?: string;
	client_line = '';
	code_agent?: string;
	code_area_manager: string;
	custom_field?: ExtraFieldOrderHeaderPouchModel;
	customer_order_ref_code?: string;
	customer_order_ref_date?: number;
	date?: number = Date.now();
	delivery_methods_object?: any; // delivery method ? table ?
	delivery_methods?: string;
	discount_agent?: DiscountModel;
	discount: DiscountModel[] = [];
	discrepancy_list?: DiscrepancyOrderEnum[];
	division = '';
	first_evasion_date?: number;
	goods_destination_object?: OrderAddressPouchModel; // destination
	invoice_requested?: boolean;
	mail_auth_sent?: number;
	note_order?: string;
	order_causal_object?: ItemDescriptionModel; // o any?
	order_causal?: string;
	order_id?: string;
	order_number?: number;
	order_progress_detail?: OrderProgressDetail;
	organization?: OrganizationPouchModel;
	payment_code?: string;
	payment_object?: any; // table ? payment ?
	price_list_code?: string;
	price?: OrderPriceModel; // o any?
	reject_reason?: RejectReason;
	shipping_method_code?: string;
	shipping_method_object?: ShippingMethodModel; // shipping method
	shipping_mode?: string;
	starting_warehouse_object?: any; // starting_warehouse
	starting_warehouse?: string;
	status?: OrderStatusEnum = OrderStatusEnum.DRAFT;
	submission_date?: number;
	weight?: number;
  currency?: CurrencyPouchModel
}

export interface BankObject {
	description: string;
	iban: string;
}

export interface RejectReason {
	manager: string;
	message: string;
	date: number;
}

export interface ExtraFieldOrderHeaderPouchModel {
	support_type?: string;
	code_cig?: string;
	code_cup?: string;
	transport?: Transport;
}

export interface Transport {
	carrier_type?: CarrierType;
	carrier_pumping_charge?: CarrierPumpingChargePouchModel;
	kilometric_range?: string;
}

export interface CarrierType {
	code: string;
	description: string;
}

export interface OrderProgressDetail {
	first_evasion_date?: number;
	goods_destination?: DestinationPouchModel;
	starting_warehouse?: string;
	order_causal?: string;
}

export class ProductVariationStateModel {
	productCode: string;
	type: ProductVariationTypeEnum;
	propertyName?: string;
	oldValue?: string;
	newValue?: string;
}

export interface Authorized {
	manager: string;
	date: number;
}
export interface CsuiteStateModel {
	order_number: number;
	order_year: number;
	order_ext_number: string;
	order_ext_progress: string;
	order_so_id: string;
	order_so_number: number;
	order_so_year: number;
	order_so_progress: string;
	order_so_series: string;
	order_so_type_acquisition: string;
}
export interface ShippingMethodModel {
	code_item: string;
	description?: string;
	charge?: number;
	delivery_days?: number;
	threshold?: number;
}

export interface ItemDescriptionModel {
	code_item?: string;
	description?: string;
	description_short?: string;
}

export interface OrderPriceModel {
	total?: number;
	article?: number;
	shipping?: number;
	vat?: number;
}

export interface OrderWithArticleDetailRequest {
	id?: string;
	orderData?: ArticleListFilterModel;
}

export interface ArticleListFilterModel {
	organization?: OrganizationStateModel;
	order?: OrderStateModel;
	division?: string;
	article: ArticleFilterModelBis;
	forceGetFromApi?: boolean;
}

// TODO: da bonificare i nomi dei modelli
export interface ArticleFilterModelBis {
	code?: string;
	description?: string;
	code_list?: string[];
	um_warehouse?: string;
	exclude?: ArticleExludeByPropertyValue;
	excludePrice?: boolean;
	has_hierarchy?: string[];
	only_without_children?: boolean;
	text?: string;
	level?: string;
	categoryList?: Category[];
}

export interface ArticleExludeByPropertyValue {
	packaging?: string;
}
